import { NgModule } from "@angular/core";
import { ChatComponent } from "./components/chat/chat.component";
import { SharedModule } from "./shared.module";

@NgModule({
    declarations: [
        ChatComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        ChatComponent,
        SharedModule
    ]
})
export class UnicreditModule {}