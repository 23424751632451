import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch'
})
export class FullTextSearchPipe implements PipeTransform {

  transform(items: Array<any>, value: string, target: string): Array<any> {
    if (!value) {
      return items;
    }
    if (target) {
      return items.filter(item => item[target].indexOf(value) !== -1);
    } else {
      return items.filter(item => item.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    }
  }

}
