import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { NgbModal, NgbDate, NgbDropdownConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { Pagination } from '../../classes/pagination';
import { PaginationSlide } from '../../classes/pagination-slide';
import { TableSortDirective } from '../../directives/table-sort.directive';
import { DialogService } from '../../services/dialog.service';
import { TrainingModalComponent } from '../modals/training-modal/training-modal.component';
import { AdminService } from '../../services/admin.service';
import { DesignService } from '../../services/design.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingComponent implements OnInit, OnDestroy {

  @ViewChild('trainingForm', {static: false}) trainingForm: NgForm;
  @ViewChild('popRequestFilter', {static: false}) popRequestFilter: NgbPopover

  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Array<Subscription> = [];
  resultResolved: boolean;
  resultsPagination: Pagination = new Pagination();
  resultSlide: PaginationSlide;
  results: Array<any> = [];
  tagFilters: any = {};

  filter: any = {};
  currentDate = new Date();
  rangePickerOptions: any = {
    required: true,
    readonly: true,
    maxDate: {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    }
  };
  filterFor: string = 'sessionId';
  idResearchTab: 'conversations' | 'assigned' | 'archived' | 'marked' = 'conversations';
  sentimentMedia = '';
  sidebarTags: { active: boolean, typeSelected?: string, label: string } = { active: false, label: 'All conversations' };
  assegnees = [];
  assigneeInputModel = 'All users';
  requestFilterCondition = 'interactionGreater';
  requestFilterNumber;
  dropdownOpened = false;
  viewCheckboxAll = false;
  checkAll = false;

  constructor(private dialogService: DialogService, private modalService: NgbModal, private adminService: AdminService, private designService: DesignService, private authenticationService: AuthenticationService) {
    let currentDateminus49 = new Date();
    currentDateminus49.setDate(currentDateminus49.getDate() - 49);
    this.rangePickerOptions.minDate = {
      day: currentDateminus49.getDate(),
      month: currentDateminus49.getMonth() + 1,
      year: currentDateminus49.getFullYear()
    }
    let currentDateFrom = new Date();
    currentDateFrom.setDate(currentDateFrom.getDate() - 7);
    this.filter.fromDate = {
      day: currentDateFrom.getDate(),
      month: currentDateFrom.getMonth() + 1,
      year: currentDateFrom.getFullYear()
    };
    this.filter.toDate = {...this.rangePickerOptions.maxDate};
  }

  ngOnInit() {
    this.filter.agentChannel = '';
    this.searchItems(1);
    this.getTagFilters();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchItems(this.resultsPagination.currentPage);
  }

  viewConversation(item: any) {
    this.resultSlide = new PaginationSlide(this.results, this.resultsPagination, item, 'sessionId');
    this.resultSlide.onMovePage.subscribe(async $event => {
      await this.searchItems($event.targetPage);
      this.resultSlide.changePage($event.direction, this.results);
    });

    const modalRef = this.modalService.open(TrainingModalComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.conversation = item;
    modalRef.componentInstance.conversationSlide = this.resultSlide;
    modalRef.componentInstance.onUpdateResults.subscribe($event => this.searchItems(this.resultsPagination.currentPage));
  }

  async searchItems(pageSelected: number) {
    this.viewCheckboxAll = false;
    this.checkAll = false;
    this.resultResolved = false;
    this.resultsPagination.onSelectPage(pageSelected);

    if (this.filterFor === 'sessionId') await this.searchConversations();
    if (this.filterFor === 'intent') await this.searchIntents();
    if (this.filterFor === 'phrase') await this.searchPhrase();

    this.resultResolved = true;
  }

  changeSentimentFilter() {
    switch (this.sentimentMedia) {
      case '':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Positive':
        this.filter.minSentimentMedia = 0.25;
        this.filter.maxSentimentMedia = 1;
        break;
      case 'Neutral':
        this.filter.minSentimentMedia = -0.250001;
        this.filter.maxSentimentMedia = 0.249999;
        break;
      case 'Negative':
        this.filter.minSentimentMedia = -1;
        this.filter.maxSentimentMedia = -0.25;
        break;
    }
    this.searchItems(1);
  }

  switchIdResearchTab() {
    this.filter = {...this.filter,
      archived: null,
      userIdNotNull: null,
      marked: null
    };
    if (!! this.filter.sortBy && this.filter.sortBy.indexOf('userEmail') >= 0) {
      this.resetSort();
    }
    if (this.idResearchTab === 'archived') this.filter.archived = 'true';
    if (this.idResearchTab === 'marked') this.filter.marked = 'true';
    else if (this.idResearchTab === 'assigned') {
      this.getAssignees();
      this.filter.userIdNotNull = 'true';
      this.filter.sortBy = 'userEmail:asc'
      this.thead.forEach((th: any) => {
        if (th.sortable !== 'userEmail') {
          th.direction = '';
        } else {
          th.direction = 'asc';
        }
      });
    }
    this.searchItems(1);
  }

  private resetSort() {
    this.filter.sortBy = null;
    this.thead.forEach((th: any) => {
      th.direction = '';
    })
  }

  searchConversations(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      sessionId: this.filter.id || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month-1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month-1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      approved: this.filter.approved,
      archived: this.filter.archived || 'false',
      marked: this.filter.marked || 'false',
      fallback: this.filter.fallback,
      minSentimentMedia: this.filter.minSentimentMedia || -1,
      maxSentimentMedia: this.filter.maxSentimentMedia || 1,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null,
      //userId: this.filter.userId || null,
      userIdNotNull: this.filter.userIdNotNull || 'false',
      withoutTopics: this.filter.withoutTopics || null,
      tags: this.filter.tags,
      sortBy: this.filter.sortBy || null,
      isLogged: this.filter.isLogged,
      fromOperator: this.filter.fromOperator,
      havePosRating: this.filter.havePosRating,
      haveNegRating: this.filter.haveNegRating,
      userMail: this.idResearchTab === 'assigned' ? this.filter.userMail : null,
      interactionGreater: this.filter.interactionGreater,
      interactionLesser: this.filter.interactionLesser,
      interactionEq: this.filter.interactionEq
    };

    if (!params.startDate || !params.endDate) return;

    return this.dialogService.getConversations(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[CONVERSATIONS]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);

    });
  }

  searchIntents(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      dfIntentName: this.filter.intent || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null,
      sortBy: this.filter.sortBy || null,
      fallback: this.filter.fallback,
      minSentimentMedia: this.filter.minSentimentMedia || -1,
      maxSentimentMedia: this.filter.maxSentimentMedia || 1
    };

    if (!params.startDate || !params.endDate) return;

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[INTENTS]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    });
  }

  searchPhrase(): Promise<void> {
    //console.log('[FILTERS]', this.filter);
    const params = {
      userQuery: this.filter.phrase || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null,
      sortBy: this.filter.sortBy || null,
      fallback: this.filter.fallback,
      minSentimentMedia: this.filter.minSentimentMedia || -1,
      maxSentimentMedia: this.filter.maxSentimentMedia || 1
    };

    if (!params.startDate || !params.endDate) return;

    return this.dialogService.getInteractionHistory(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).toPromise().then((response: any) => {
      //console.log('[PHRASE]', response);
      this.results = response.data;
      this.resultsPagination.updateTotals(response.totalElements);
    })
  }

  getAssignees() {
    const params = {
      startDate: new Date(this.rangePickerOptions.minDate.year, this.rangePickerOptions.minDate.month-1, this.rangePickerOptions.minDate.day, 0, 0, 0).getTime(),
      endDate: new Date(this.rangePickerOptions.maxDate.year, this.rangePickerOptions.maxDate.month-1, this.rangePickerOptions.maxDate.day, 23, 59, 59).getTime(),
    }
    this.dialogService.getAssignees(params).subscribe((data: any[]) => {
      this.assegnees = data;
    })
  }

  updateDate($event) {
    if ($event.type === 'from') {
      this.filter.fromDate = $event.date;
      if (this.filter.fromDate instanceof NgbDate) this.searchItems(1);
    }
    if ($event.type === 'to') {
      this.filter.toDate = $event.date;
      if (this.filter.toDate instanceof NgbDate) this.searchItems(1);
    }

  }

  archiveConversation(conversation: any) {
    const data = [{ id: conversation.id }];
    this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation(data).subscribe(async (response: any) => {
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  markConversation(conversation: any) {
    const data = [{ id: conversation.id }];
    this.subscriptions['MarkConversation'] = this.dialogService.markConversation(data).subscribe(async (response: any) => {
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  assignConversation(conversation: any) {
    const ids = [conversation.id];
    this.subscriptions['AssignConversation'] = this.dialogService.assignConversation(ids, this.authenticationService.user.email).subscribe(() => this.searchItems(this.resultsPagination.currentPage));
  }

  getTagFilters() {
    this.subscriptions['TagFilters'] = this.adminService.getTrainerTagsTree().subscribe((response: any) => {
      this.tagFilters = response;
    });
  }

  selectTagFilter(tagName: Array<string> | string, tagType?: 'mine' | 'other' | 'allMine' | 'allOther' | 'without') {
    this.filter.withoutTopics = (tagType === 'without') ? true : null;
    if (Array.isArray(tagName)) {
      this.filter.tags = [];
      tagName.forEach(tag => this.filter.tags.push(tag));
      this.sidebarTags.label = (tagType === 'allMine') ? 'Your topics' : 'Other topics'
    } else {
      this.filter.tags = tagName;
      this.sidebarTags.label = (tagName) ? tagName : (tagType === 'without') ? 'Without topics' : 'All conversations';
    }

    this.sidebarTags = {...this.sidebarTags,
      active: false,
      typeSelected: tagType || null
    };
    this.searchItems(1);
  }

  resetFilters() {
    let currentDateFrom = new Date();
    currentDateFrom.setDate(currentDateFrom.getDate() - 7);
    this.filter = {
      agentChannel : '',
      marked: this.filter.marked,
      archived: this.filter.archived,
      userIdNotNull: this.filter.userIdNotNull,
      fromDate: {
        day: currentDateFrom.getDate(),
        month: currentDateFrom.getMonth() + 1,
        year: currentDateFrom.getFullYear()
      },
      toDate: {...this.rangePickerOptions.maxDate},
      sortBy: this.idResearchTab === 'assigned' ? 'userEmail:asc' : undefined
    };
    this.thead.forEach((th: any) => {
      if (this.idResearchTab === 'assigned' && th.sortable === 'userEmail') {
        th.direction = 'asc';
      } else th.direction = ''
    });
    this.sidebarTags = { active: false, label: 'All conversations' };
    this.sentimentMedia = '';
    this.assigneeInputModel = 'All users';
    this.requestFilterCondition = 'interactionGreater';
    this.requestFilterNumber = null;
    this.searchItems(1);
  }

  handleChebox() {
    if (this.results.find(el => el.checked)) {
      this.viewCheckboxAll = true;
    } else {
      this.viewCheckboxAll = false;
    }
  }

  handleCheboxAll() {
    this.results.forEach(el => {
      el.checked = this.checkAll;
    })
    this.viewCheckboxAll = this.checkAll;
  }

  multipleAssign() {
    const ids = [];
    this.results.forEach(el => {
      if (el.checked) {
        ids.push(el.id);
      }
    })
    this.resultResolved = false;
    this.subscriptions['AssignConversation'] = this.dialogService.assignConversation(ids, this.authenticationService.user.email).subscribe(() => this.searchItems(this.resultsPagination.currentPage));
  }

  multipleArchive() {
    const data = [];
    this.results.forEach(el => {
      if (el.checked) {
        data.push({id: el.id });
      }
    });
    this.resultResolved = false;
    this.subscriptions['ArchiveConversation'] = this.dialogService.archiveConversation(data).subscribe(async (response: any) => {
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  multipleMark() {
    const data = [];
    this.results.forEach(el => {
      if (el.checked) {
        data.push({id: el.id });
      }
    });
    this.resultResolved = false;
    this.subscriptions['MarkConversation'] = this.dialogService.markConversation(data).subscribe(async (response: any) => {
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  multipleDisassign() {
    const ids = [];
    this.results.forEach(el => {
      if (el.checked) {
        ids.push(el.id);
      }
    })
    this.resultResolved = false;
    this.dialogService.unassignConversation(ids).subscribe(() => this.searchItems(this.resultsPagination.currentPage));
  }

  multipleUnmark() {
    const data = [];
    this.results.forEach(el => {
      if (el.checked) {
        data.push({id: el.id });
      }
    });
    this.resultResolved = false;
    this.subscriptions['UnmarkConversation'] = this.dialogService.unmarkConversation(data).subscribe(async (response: any) => {
      this.searchItems(this.resultsPagination.currentPage);
    });
  }

  cancelCheckbox() {
    this.checkAll = false;
    this.handleCheboxAll();
  }

  countFilters() {
    let count = 0;
    if (this.filter.isLogged && this.filterFor === 'sessionId') count++;
    if (this.filter.fallback) count++;
    if (this.filter.fromOperator && this.filterFor === 'sessionId') count++;
    if (this.filter.haveRating  && this.filterFor === 'sessionId') count++;
    if (this.filter.agentChannel) count++;
    if (this.sentimentMedia) count++;
    if (this.filter.approved != null && this.filterFor === 'sessionId') count++;
    return count;
  }

  filterForAssignee(assignee, popAssignee, current = false) {
    this.assigneeInputModel = current ? this.authenticationService.user.email : assignee;
    this.filter.userMail = this.assigneeInputModel;
    popAssignee.close();
    this.searchItems(1);
  }

  filterRequests(clear = false) {
    this.clearFilterRequests();
    if (!clear) {
      this.filter[this.requestFilterCondition] = this.requestFilterNumber;
    } else {
      this.requestFilterCondition = 'interactionGreater';
      this.requestFilterNumber = null;
    }
    this.popRequestFilter.close();
    this.searchItems(1);
  }

  private clearFilterRequests() {
    this.filter.interactionGreater =  null;
    this.filter.interactionLesser = null;
    this.filter.interactionEq = null;
  }
}
