
export class Pagination {
    pageTarget: number = 1;
    currentPage: number = 1;
    pageSize: number;
    totalPages: number;
    totalItems: number;
    minPaginationSize = 10;

    constructor(pageSize?: number) {
        this.pageSize = pageSize || 10;
    }

    onSelectPage(pageNumber: number) {
        this.pageTarget = pageNumber;
        this.currentPage = pageNumber;
    }

    getPageIndex(): number {
        return this.currentPage - 1;
    }

    updateTotals(totalItems: number) {
        this.totalItems = totalItems;
        this.totalPages = Math.ceil(totalItems / this.pageSize);
    }
}
