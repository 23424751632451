import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class RoleRestrictionGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService, private notificationService: NotificationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authenticationService.isAuthorized(next.data.roleRestriction)) {
      return true;
    } else {
      if ((state.url === '/intent-settings' || state.url === '/validation') && !this.authenticationService.isAuthorized(next.data.roleRestriction)) {
        this.router.navigate(['/analytics']);
        return false;
      } else if (state.url === '/analytics' && !this.authenticationService.isAuthorized(next.data.roleRestriction)) {
        this.router.navigate(['/training']);
        return false;
      } else {
        this.authenticationService.eraseSession();
        this.notificationService.showToast('You don\'t have right permission to sign in, please contact the amministrations', { type: 'warning' });
        return false;
      }
    }
  }
  
}