import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';

import { Pagination } from '../../../classes/pagination';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditLogComponent implements OnInit, OnDestroy {
  @ViewChild('auditlogForm', {static: false}) auditlogForm: NgForm;
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>

  auditLogSubscription: Subscription;
  auditTypesSubscription: Subscription;
  exportCSVSubscription: Subscription;
  auditLogPagination: Pagination = new Pagination();

  filter: any = {
    eventType: '',
    component: ''
  };
  auditLog: Array<any> = [];
  auditTypes: Array<string> = [];
  currentDate = new Date();
  rangePickerOptions: any = {
    required: true,
    readonly: true,
    maxDate: {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    }
  };
  componentsTypes: Array<string> = [];
  resetSort = false;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.getComponentsTypes();
    this.auditTypesSubscription = this.adminService.getAuditTypes().subscribe((response: any) => {
      //console.log('[AUDIT_TYPES]', response);
      this.auditTypes = response;
    });
    this.searchAuditLog(1);
  }

  ngOnDestroy() {
    this.auditTypesSubscription.unsubscribe();
    this.auditLogSubscription.unsubscribe();
    if (this.exportCSVSubscription) this.exportCSVSubscription.unsubscribe();
  }

  updateDate($event) {
    //console.log('[EVENT]', $event);
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  getComponentsTypes() {
    this.adminService.getComponentsTypes().subscribe((response: any) =>{
      this.componentsTypes = response;
    })
  }

  searchAuditLog(pageSelected: number) {
    //console.log('[FILTERS]', this.filter);
    this.auditLogPagination.onSelectPage(pageSelected);
    if (this.resetSort) {
      this.thead.forEach((th: any) => th.direction = '');
      this.resetSort = false;
    }

    const params = {
      component: this.filter.component || null,
      eventType: this.filter.eventType || null,
      principal: this.filter.principal || null,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      sortBy: this.filter.sortBy || null
    };

    this.auditLogSubscription = this.adminService.getAuditLog(params, this.auditLogPagination.getPageIndex(), this.auditLogPagination.pageSize).subscribe((response: any) => {
      //console.log('[AUDIT_LOG]', response);
      this.auditLog = response.content;
      this.auditLogPagination.updateTotals(response.totalElements);
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchAuditLog(this.auditLogPagination.currentPage);
  }

  resetFilters() {
    this.filter = {
    eventType: '',
    component: ''
    };
    this.resetSort = true;
  }

  exportCSV(){
    const params = {
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : new Date(2000,1,1,0,0,0).getTime(),
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : new Date(2100,1,1,0,0,0).getTime(),
      eventType: this.filter.eventType || null,
      component: this.filter.component || null,
      principal: this.filter.principal || null
    };

    this.exportCSVSubscription = this.adminService.getCSV(params).subscribe((response: any) => {
      const elementDom = document.createElement('a'); 
      const blob = new Blob([response], { type: 'text/csv' });
      const url= window.URL.createObjectURL(blob);
      elementDom.href = url ;
      elementDom.download = 'export.csv';
      elementDom.click();
      URL.revokeObjectURL(url);
      
    })
  }
}
