import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(data: any) {
    const httpOptions = {
      params: new HttpParams()
        .set('cleanResponse', 'false')
        .set('isAdminConsole', 'true')
    };

    return this.http.post(`${environment.API.orchestrator_chat}/orchestrate`, data, httpOptions);
  }

}
