import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PlaceholderTypes } from 'src/app/enums/placeholder-types.enum';
import { PlaceholderService } from 'src/app/services/placeholder.service';

@Component({
  selector: 'basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss']
})
export class BasicCardComponent implements OnInit {

  @Input() cardName = 'Basic Card';

  @Input() form: FormGroup;

  @Input() placeholderName;

  @Input() canUsePlaceholder = true;

  @Output() delete = new EventEmitter<any>();

  @ViewChild('placeholderThInstance', {static: false}) placeholderThInstance: NgbTypeahead;

  subscriptions: Array<Subscription> = [];

  layoutData = ['center', 'left', 'right'];

  targetData = ['_blank', '_self', '_parent', '_top'];
  
  /* --- START PLACEHOLDER TYPEHEAD --- */
  placeholderSearch = false;
  placeholderData: any[];
  focusPlaceholder$ = new Subject<string>();
  clickPlaceholder$ = new Subject<string>();

  selectedPlaceholder = false;

  thPlaceholderSuggestions = data => data.key;
  thPlaceholderSelect = data => data.key;

  searchPlaceholder = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickPlaceholder$.pipe(filter(() => !this.placeholderThInstance.isPopupOpen()));
    const inputFocus$ = this.focusPlaceholder$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => term === '' ? this.placeholderData
        : this.placeholderData.filter(placeholder => placeholder.key.toUpperCase().indexOf(term.toUpperCase()) >= 0))
    )
  }
  /* --- END PLACEHOLDER TYPEHEAD --- */

  constructor(private placeholderService: PlaceholderService) { }

  ngOnInit() {
    let placeholderKey = this.form.get('placeholderKey').value;
    if (!!placeholderKey) {
      this.selectedPlaceholder = true;
      this.disableBasicCardElements();
    } else {
      this.form.get('placeholderKey').disable();
    }
  }

  remove() {
    this.placeholderSearch = false;
    this.selectedPlaceholder = false;
    this.delete.emit();
  }

  usePlaceholder() {
    this.subscriptions['Placeholder'] = this.placeholderService.getPlaceholder({type: PlaceholderTypes.BASIC_CARD}).subscribe(
      data => {
        this.placeholderSearch = true;
        //this.placeholderData = data;
        this.placeholderData = data.filter(el => el.key !== this.placeholderName);
      }
    );
  }

  choosePlaceholder(event: any) {
    this.disableBasicCardElements();
    this.form.get('placeholderKey').enable();
    this.form.get('placeholderKey').patchValue(event.item.key);
    this.selectedPlaceholder = true;
    this.placeholderSearch = false;
  }

  defineNewCard() {
    this.form.get('placeholderKey').disable();
    this.enableBasicCardElements();
    setTimeout(() =>
    this.selectedPlaceholder = false);
  }

  private disableBasicCardElements() {
    Object.keys(this.form.controls).forEach(controlName => {
      if(controlName !== 'placeholderKey') {
        this.form.get(controlName).disable();
      }
    });
  }

  private enableBasicCardElements() {
    Object.keys(this.form.controls).forEach(controlName => {
      if(controlName !== 'placeholderKey') {
        this.form.get(controlName).enable();
      }
    });
  }
}
