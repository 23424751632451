import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FullTextSearchPipe } from './pipes/full-text-search.pipe';
import { Ng5SliderModule } from 'ng5-slider';
import { AngularResizedEventModule } from 'angular-resize-event';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { ConsoleHeaderComponent } from './components/console-header/console-header.component';
import { ConsoleMenuComponent } from './components/console-menu/console-menu.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { RatingModalComponent } from './components/modals/rating-modal/rating-modal.component';
import { ToastNotificationComponent } from './components/utility/toast-notification/toast-notification.component';
import { DatepickerRangeComponent } from './components/utility/datepicker-range/datepicker-range.component';
import { LoginComponent } from './components/login/login.component';
import { TrainingComponent } from './components/training/training.component';
import { TrainingModalComponent } from './components/modals/training-modal/training-modal.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { AuditLogComponent } from './components/admin/audit-log/audit-log.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AnalyticsFilterComponent } from './components/analytics/analytics-filter/analytics-filter.component';
import { AnalyticSessionsComponent } from './components/analytics/analytic-sessions/analytic-sessions.component';
import { ConversationalHistoryComponent } from './components/analytics/conversational-history/conversational-history.component';
import { AnalyticIntentsComponent } from './components/analytics/analytic-intents/analytic-intents.component';
import { AnalyticProficiencyComponent } from './components/analytics/analytic-proficiency/analytic-proficiency.component';
import { ChatDebugModalComponent } from './components/modals/chat-debug-modal/chat-debug-modal.component';
import { EntitySelectionDirective } from './directives/entity-selection.directive';
import { IntentsReliabilityComponent } from './components/intents-reliability/intents-reliability.component';
import { StarRatingComponent } from './components/utility/star-rating/star-rating.component';
import { AccessManagementComponent } from './components/admin/access-management/access-management.component';
import { TableSortDirective } from './directives/table-sort.directive';
import { IntentParametersComponent } from './components/intent-parameters/intent-parameters.component';
import { IntentParametersModalComponent } from './components/modals/intent-parameters-modal/intent-parameters-modal.component';
import { IntentTagsComponent } from './components/intent-tags/intent-tags.component';
import { IntentSettingsComponent } from './components/intent-settings/intent-settings.component';
import { RoleRestrictionDirective } from './directives/role-restriction.directive';
import { StepsCompletionComponent } from './components/utility/steps-completion/steps-completion.component';
import { SupportModalComponent } from './components/modals/support-modal/support-modal.component';
import { ChatSettingsModalComponent } from './components/modals/chat-settings-modal/chat-settings-modal.component';
import { TrainingTeamComponent } from './components/admin/training-team/training-team.component';
import { TopicsModalComponent } from './components/modals/topics-modal/topics-modal.component';
import { RatingComponent } from './components/utility/rating/rating.component';
import { GraphicsPayloadModalComponent } from './components/modals/graphics-payload-modal/graphics-payload-modal.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { BasicCardComponent } from './components/modals/graphics-payload-modal/basic-card/basic-card.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PayloadFormComponent } from './components/modals/graphics-payload-modal/payload-form/payload-form.component';
import { GraphicFormComponent } from './components/modals/graphics-payload-modal/graphic-form/graphic-form.component';
import { SimpleChipComponent } from './components/modals/graphics-payload-modal/simple-chip/simple-chip.component';
import { SimpleLinkComponent } from './components/modals/graphics-payload-modal/link/simple-link.component';
import { SimpleButtonComponent } from './components/modals/graphics-payload-modal/simple-button/simple-button.component';
import { PaginationComponent } from './components/utility/pagination/pagination.component';
import { ValidationComponent } from './components/validation/validation.component';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { IntentParametersValidatorDirective } from './directives/intent-parameters-validator.directive';
import { DictionaryComponent } from './components/dictionary/dictionary.component';
import { DictionaryModalComponent } from './components/modals/dictionary-modal/dictionary-modal.component';
import { strategy } from 'src/strategy/strategy';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ToastNotificationComponent,
    DatepickerRangeComponent,
    FullTextSearchPipe,
    ConsoleHeaderComponent,
    ConsoleMenuComponent,
    LoginComponent,
    NotificationModalComponent,
    TrainingComponent,
    TrainingModalComponent,
    AdminComponent,
    UserModalComponent,
    AuditLogComponent,
    AnalyticsComponent,
    AnalyticsFilterComponent,
    AnalyticSessionsComponent,
    ConversationalHistoryComponent,
    AnalyticIntentsComponent,
    AnalyticProficiencyComponent,
    RatingModalComponent,
    ChatDebugModalComponent,
    EntitySelectionDirective,
    IntentsReliabilityComponent,
    StarRatingComponent,
    AccessManagementComponent,
    TableSortDirective,
    IntentParametersModalComponent,
    IntentParametersComponent,
    IntentTagsComponent,
    IntentSettingsComponent,
    RoleRestrictionDirective,
    StepsCompletionComponent,
    SupportModalComponent,
    ChatSettingsModalComponent,
    TrainingTeamComponent,
    TopicsModalComponent,
    RatingComponent,
    GraphicsPayloadModalComponent,
    PlaceholderComponent,
    BasicCardComponent,
    PayloadFormComponent,
    GraphicFormComponent,
    SimpleChipComponent,
    SimpleLinkComponent,
    SimpleButtonComponent,
    PaginationComponent,
    ValidationComponent,
    SafeStylePipe,
    IntentParametersValidatorDirective,
    DictionaryComponent,
    DictionaryModalComponent
  ],
  imports: [
    Ng5SliderModule,
    AppRoutingModule,
    HttpClientModule,
    AngularResizedEventModule,
    CarouselModule,
    strategy.StrategyModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NotificationModalComponent,
    TrainingModalComponent,
    UserModalComponent,
    RatingModalComponent,
    ChatDebugModalComponent,
    IntentParametersModalComponent,
    SupportModalComponent,
    TopicsModalComponent,
    ChatSettingsModalComponent,
    GraphicsPayloadModalComponent,
    DictionaryModalComponent
  ]
})
export class AppModule { }
