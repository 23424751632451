import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() rateScore: any;
  isThumbUp: boolean = false;
  
  constructor() { }

  ngOnInit() {
     (this.rateScore > 0) ?  this.isThumbUp = true : this.isThumbUp = false;
  }
}
