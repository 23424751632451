import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IntentService } from 'src/app/services/intent.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-intent-parameters-modal',
  templateUrl: './intent-parameters-modal.component.html',
  styleUrls: ['./intent-parameters-modal.component.scss'],
})
export class IntentParametersModalComponent implements OnInit, OnDestroy {
  @Input() action: 'create' | 'edit' | 'clone';
  @Input() intent?: any;
  @Output() onSaveResponse = new EventEmitter<any>();
  formSubmitted = false;
  responseSubscription: Subscription;

  get responseType(): 'text' | 'event' {
    return this.responsesForm.value.responseType;
  }

  get parametersForm(): FormArray {
    return this.responsesForm.get('parameters') as FormArray;
  }

  get textResponses() {
    return this.responsesForm.get('response') as FormArray;
  }

  responsesForm = this.fb.group({
    id: this.fb.control(''),
    intentId: this.fb.control(''),
    intentName: this.fb.control('', Validators.required),
    context: this.fb.control(''),
    responseType: this.fb.control('text'),
    event: this.fb.control({ value: '', disabled: true }, Validators.required),
    payload: this.fb.control(''),
    parameters: this.fb.array([]),
    response: this.fb.array([]),
  });

  intentsSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.intentService.getIntentsSuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
    );

  intentSuggestionFormat = (value: any) => value.name;
  intentSuggestionSelect = (value: any) => (value.name ? value.name : value);

  chooseIntent($event: any) {
    this.responsesForm.get('intentId').setValue($event.item.id);
    this.responsesForm.get('intentName').setValue($event.item.name);
    $event.preventDefault();
  }

  paramFactory(paramName = '', paramValue = '') {
    const paramForm = this.fb.group({
      paramName: this.fb.control(paramName, Validators.required),
      paramValue: this.fb.control(paramValue, Validators.required),
      context: this.fb.control({ value: '', disabled: true }),
    });

    if (paramName && paramName.indexOf('.') !== -1) {
      const [context, name] = paramName.split('.');
      paramForm.get('context').enable();
      paramForm.patchValue({
        paramName: name,
        context,
      });
    }
    return paramForm;
  }

  constructor(private notificationService: NotificationService, private intentService: IntentService, public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit() {
    this.subscribeResponseType();

    if (this.action === 'create') {
      this.addResponseText();
      this.addIntentParameter();
    } else if (this.action === 'edit') {
      this.processForm();
      this.responsesForm.patchValue({
        id: this.intent.id,
        intentId: this.intent.intentId,
        intentName: this.intent.intentName,
        context: this.intent.context,
        responseType: this.intent.event ? 'event' : 'text',
        event: this.intent.event,
        payload: this.intent.payload,
      });
    } else if (this.action === 'clone') {
      this.processForm();
      this.responsesForm.patchValue({
        intentId: this.intent.intentId,
        intentName: this.intent.intentName,
        context: this.intent.context,
        responseType: this.intent.event ? 'event' : 'text',
        event: this.intent.event,
        payload: this.intent.payload,
      });
    }
  }

  ngOnDestroy() {
    if (this.responseSubscription instanceof Subscription) this.responseSubscription.unsubscribe();
  }

  subscribeResponseType() {
    this.responsesForm.get('responseType').valueChanges.subscribe((value) => {
      if (value === 'event') {
        this.responsesForm.get('event').enable();
        this.responsesForm.get('response').disable();
      } else {
        this.responsesForm.get('response').enable();
        this.responsesForm.get('event').disable();
      }
    });
  }

  restrictContextParsing(event: KeyboardEvent) {
    if (event.key === '.') event.preventDefault();
  }

  addIntentParameter() {
    this.parametersForm.push(this.paramFactory());
  }

  addResponseText() {
    this.textResponses.push(this.fb.control('', Validators.required));
  }

  toggleContextForm(paramForm: FormGroup) {
    if (paramForm.get('context').enabled) {
      paramForm.get('context').disable();
      paramForm.updateValueAndValidity();
    } else {
      paramForm.get('context').enable();
      paramForm.updateValueAndValidity();
    }
  }
  saveResponse() {
    this.formSubmitted = true;

    if (this.responsesForm.invalid) return;

    const formValue = this.responsesForm.value;
    const data = {
      id: formValue.id ? formValue.id : null,
      intentId: formValue.intentId,
      intentName: formValue.intentName,
      context: formValue.context ? formValue.context : null,
      event: formValue.responseType === 'event' ? formValue.event : null,
      payload: formValue.responseType === 'text' ? formValue.payload : null,
      response: formValue.responseType === 'text' ? formValue.response : [],
      parameters: this.parametersForm.controls.map((paramForm) => ({
        paramName: paramForm.get('context').enabled ? `${paramForm.value.context}.${paramForm.value.paramName}` : paramForm.value.paramName,
        paramValue: paramForm.value.paramValue,
      })),
    };

    if (this.action === 'create' || this.action === 'clone') {
      this.responseSubscription = this.intentService.createIntentParameters(data).subscribe(
        (response: any) => {
          //console.log('[CREATE_RESPONSE]', response);
          this.activeModal.close();
          this.onSaveResponse.emit();
        },
        () => () => this.onError()
      );
    } else if (this.action === 'edit') {
      this.responseSubscription = this.intentService.updateIntentParameters(data).subscribe(
        (response: any) => {
          //console.log('[UPDATE_RESPONSE]', response);
          this.activeModal.close();
          this.onSaveResponse.emit();
        },
        () => this.onError()
      );
    }
  }

  onError() {
    this.notificationService.openModal({
      title: `Service error`,
      message: `<div class="alert alert-danger mb-0" role="alert">Esiste già una risposta con gli stessi parametri per l'intento ${this.responsesForm.value.intentName}</div>`,
      type: 'danger',
    });
  }

  processForm() {
    this.intent.response && this.intent.response.forEach((resp) => this.textResponses.push(this.fb.control(resp, Validators.required)));
    this.intent.parameters && this.intent.parameters.forEach((param) => this.parametersForm.push(this.paramFactory(param.paramName, param.paramValue)));
  }
}
