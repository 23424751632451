import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './guards/authentication.guard';
import { RoleRestrictionGuard } from './guards/role-restriction.guard';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { TrainingComponent } from './components/training/training.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AdminComponent } from './components/admin/admin.component';
import { IntentSettingsComponent } from './components/intent-settings/intent-settings.component';
import { ValidationComponent } from './components/validation/validation.component';

const routes: Routes = [
  { path: '', redirectTo: '/intent-settings', pathMatch: 'full' },
  { path: '',
    component: MainComponent,
    canActivateChild: [AuthenticationGuard],
    children: [
      {
        path: 'training',
        component: TrainingComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'TRAINER', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'intent-settings',
        component: IntentSettingsComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'ANALYST', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'validation',
        component: ValidationComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN', 'CONVERSATIONAL_DESIGNER']
        }
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['ADMIN']
        }
      }
    ]
  },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
