import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  filter: any = {};

  constructor() { }

  ngOnInit() {
    let today = new Date();
    let aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    this.filter = {
      period: {
        fromDate: {
          day: aWeekAgo.getDate(),
          month: aWeekAgo.getMonth() + 1,
          year: aWeekAgo.getFullYear()
        },
        toDate: {
          day: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear()
        },
      },
      intentTag: '',
      agentChannel: ''
    }
  }

  ngOnDestroy() {
  }

  processChartData(chart: string, data: any): Array<Array<any>> {
    let chartData: Array<Array<any>> = [];
    if (!data) return chartData;
    switch (chart) {
      case 'ConversationsStatistics':
        chartData.push(['Date', 'Sessions']);
        data.forEach((date: any) => {
          chartData.push([new Date(date.currentDate), date.value]);
        });
        break;
      case 'PopularTopicsAnalytics':
        chartData.push(['Tags', 'Value']);
        data.forEach((tag: any) => {
          chartData.push([tag.name, tag.number]);
        });
        break;
      case 'TopIntent':
      case 'TopFallback':
        chartData.push(['Intent', 'Count']);
        data.forEach((intent: any) => {
          chartData.push([intent.name, intent.value]);
        });
        break;
        case 'TopFallback':
          case 'TopTopics':
          chartData.push(['Intent', 'Count', { role: 'annotation' }]);
          data.forEach((intent: any) => {
            chartData.push([intent.name, intent.value, `${intent.innerName}: ${intent.innerValue}`]);
          });
          break;
    }
    return chartData;
  }

}
