import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import { Subscription } from 'rxjs';

import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { PlaceholderService } from 'src/app/services/placeholder.service';
import { Pagination } from '../../classes/pagination';
import { NotificationService } from '../../services/notification.service';
import { GraphicsPayloadModalComponent } from '../modals/graphics-payload-modal/graphics-payload-modal.component';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';

@Component({
  selector: 'placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  subscriptions: Array<Subscription> = [];

  filter: any = {
    placeholder: '',
    type: '',
    sortBy: 'key:asc'
  };

  placeholderTypes: Array<string> = [];
  placeholders: Array<any> = [];
  
  constructor(private placeholderService: PlaceholderService, private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit() {
    this.getPlaceholderTypes();
    this.searchPlaceholder();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getPlaceholderTypes() {
    this.placeholderService.getPayloadTypes().subscribe((response: any) =>{
      this.placeholderTypes = response;
    })
  }


  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchPlaceholder();
  }

  searchPlaceholder() {
    const params = {
      placeholder: this.filter.placeholder || null,
      type: this.filter.type || null,
      sortBy: this.filter.sortBy || null
    };

    this.subscriptions['Placheholders'] = this.placeholderService.getPlaceholder(params).subscribe((response: any) => {
      console.log('[PLACEHOLDERS]', response);
      this.placeholders = response;
    });
  }

  deletePlaceholder(placeholder: string) {
    this.notificationService.openModal({
      title: 'Delete response',
      message: 'Are you sure you want to delete the response?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.placeholderService.deletePlaceholder(placeholder).subscribe((response: any) => {
        this.searchPlaceholder();
        //this.searchIntents(this.intentsPagination.currentPage);
      });
    });
  }

  copyToClipboardPlaceholder(key) {
    let selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = key;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showToast(key + ' Copied to clipboard', { type: 'success' });
  }
  
  managePlaceholder(action: 'CREATE' | 'EDIT', placeholder?: any) {
    const modalRef = this.modalService.open(GraphicsPayloadModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.placeholderTypes = this.placeholderTypes;
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.onSaveResponse.subscribe($event => this.searchPlaceholder());
    if (action === 'EDIT') modalRef.componentInstance.placeholderObject = placeholder;

  }
}
