import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Pagination } from '../../../classes/pagination';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() paginationCallback = new EventEmitter<number>();
  @Input() paginationData: Pagination;
  @Input() resultPerPage = true;

  constructor() { }

  ngOnInit() {
  }

  changePage(page: number) {
    this.paginationCallback.emit(page);
  }
}
