import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(private http: HttpClient) { }

  getDictionary(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        //.set('environment', params.environment)
        .set('pageSize', pageSize.toString())
        .set('pageIndex', pageNumber.toString())
        .set('journeyApiKey', environment.API.journeyKey)
    };
    if (params.value) httpOptions.params = httpOptions.params.append('value', params.value);
    if (params.type) httpOptions.params = httpOptions.params.append('type', params.type);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    
    return this.http.get(`${environment.API.orchestrator_configurator}/dictionary`, httpOptions);
  }

  getDictionaryTypes() {
    return this.http.get(`${environment.API.orchestrator_configurator}/dictionary/types`);
  }

  deleteDictionary(id: number) {
    return this.http.delete(`${environment.API.orchestrator_configurator}/dictionary/${id}`);
  }

  createDictionary(data) {
    return this.http.post(`${environment.API.orchestrator_configurator}/dictionary`, data);
  }

  updateDictionary(data) {
    return this.http.put(`${environment.API.orchestrator_configurator}/dictionary`, data);
  }
}
