import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../classes/pagination';
import { NotificationService } from '../../services/notification.service';
import { IntentService } from '../../services/intent.service';
import { IntentParametersModalComponent } from '../modals/intent-parameters-modal/intent-parameters-modal.component';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';
import { TEST_INTENTS_PARAMETERS } from 'src/app/tests/calls.mock';

@Component({
  selector: 'intent-parameters',
  templateUrl: './intent-parameters.component.html',
  styleUrls: ['./intent-parameters.component.scss'],
})
export class IntentParametersComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Array<Subscription> = [];
  intentsPagination: Pagination = new Pagination();
  filterFor: 'intent' | 'paramName' | 'paramValue' = 'intent';
  defaultSort = 'name:asc';

  filter: any = {
    sortBy: this.defaultSort,
  };

  intents: Array<any> = [];

  constructor(private intentService: IntentService, private modalService: NgbModal, private notificationService: NotificationService) {}

  ngOnInit() {
    this.searchIntents(1);
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  searchIntents(pageSelected: number) {
    this.intentsPagination.onSelectPage(pageSelected);

    const params = {
      intentName: this.filterFor === 'intent' ? this.filter.intentName : undefined,
      paramName: this.filterFor === 'paramName' ? this.filter.paramName : undefined,
      paramValue: this.filterFor === 'paramValue' ? this.filter.paramValue : undefined,
      sortBy: this.filter.sortBy || null,
    };

    //If you are filtering by IntentName
    if (params.intentName != null || params.paramName || params.paramValue) params.sortBy = this.filter.sortBy + '&id:' + this.filter.sortBy.split(':')[1];

    this.subscriptions['Intents'] = this.intentService.getIntentsParameters(params, this.intentsPagination.getPageIndex(), this.intentsPagination.pageSize).subscribe((response: any) => {
      //console.log('[INTENTS]', response);
      this.intents = response.content;
      this.intentsPagination.updateTotals(response.totalElements);
    });
  }

  onSort({ column, direction }: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = direction ? `${column}:${direction}` : null;
    this.searchIntents(this.intentsPagination.currentPage);
  }

  manageResponse(action: 'create' | 'edit' | 'clone', intent?: any) {
    const modalRef = this.modalService.open(IntentParametersModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.onSaveResponse.subscribe(($event) => this.searchIntents(this.intentsPagination.currentPage));
    if (action === 'edit' || action === 'clone') modalRef.componentInstance.intent = intent;
  }

  deleteResponse(intentParamId: number) {
    this.notificationService
      .openModal({
        title: 'Delete response',
        message: 'Are you sure you want to delete the response?',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (!confirm) return;

        this.intentService.deleteIntentParameters(intentParamId).subscribe((response: any) => {
          this.searchIntents(this.intentsPagination.currentPage);
        });
      });
  }

  resetSort() {
    this.filter.sortBy = this.defaultSort;
    this.thead.forEach((th: any) => {
      th.direction = '';
    });
  }

  getResponses(intent) {
    return intent.intentParameters.filter((resp) => resp.response && resp.response.length > 0).length;
  }

  getEvents(intent) {
    return intent.intentParameters.filter((resp) => resp.event).length;
  }
}
