import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export const parameterValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let errors: any = {};
    if (control.value.parameterName2 || control.value.parameterValue2 || control.value.contextName2) {
      if (!control.value.parameterName2) errors.parameterName2Required = true;
      if (!control.value.parameterValue2) errors.parameterValue2Required = true; 
    }
    if (control.value.parameterName3|| control.value.parameterValue3 || control.value.contextName3) {
      if (!control.value.parameterName3) errors.parameterName3Required = true;
      if (!control.value.parameterValue3 ) errors.parameterValue3Required = true; 
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };


@Directive({
  selector: '[intentParametersValidator]',
  providers: [{provide: NG_VALIDATORS, multi: true, useExisting: IntentParametersValidatorDirective}]
})
export class IntentParametersValidatorDirective implements Validator {

  constructor() { }
  validate(control: AbstractControl): ValidationErrors {
    return parameterValidator(control);
  }

}
