import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChildren } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {
  @ViewChildren('rangeFrom') rangeFrom: any;
  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;

  @Input() parentForm?: NgForm;
  @Input() options?: { required: boolean, readonly: boolean, minDate: NgbDate, maxDate: NgbDate } = {
    required: false,
    readonly: false,
    minDate: null,
    maxDate: null
  };

  @Output() onChangeDate = new EventEmitter<any>();

  fromModel: string;
  toModel: string;
  hoveredDate: NgbDate;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) { }

  ngOnInit() {
    if (this.fromDate) this.fromModel = `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year}`;
    if (this.toDate) this.toModel = `${this.toDate.day}/${this.toDate.month}/${this.toDate.year}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('[CHANGES]', changes);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.fromModel = `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year}`;

      this.onChangeDate.emit({ date: date, type: 'from' });
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.toModel = `${this.toDate.day}/${this.toDate.month}/${this.toDate.year}`;

      this.onChangeDate.emit({ date: date, type: 'to' });
    } else {
      this.toDate = null;
      this.toModel = null;
      this.fromDate = date;
      this.fromModel = `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year}`;

      this.onChangeDate.emit({ date: null, type: 'to' });
      this.onChangeDate.emit({ date: date, type: 'from' });
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  isEnabled(date: NgbDate) {
    return date.before(this.options.minDate) || date.after(this.options.maxDate);
  }

  validateInput(currentValue: NgbDate, input: string, rangeType: 'from' | 'to'): NgbDate {
    const parsed = this.formatter.parse(input);
    if ( parsed && this.calendar.isValid(NgbDate.from(parsed)) ) {
      this.onChangeDate.emit({ date: NgbDate.from(parsed), type: rangeType });
      return NgbDate.from(parsed);
    }
    return null;
  }

  isFieldInvalid(model, form) {
    return model.invalid && !!form && form.submitted ? 'is-invalid' : ''; 
  }

}
