import { Component, OnInit, OnDestroy} from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';

import { DesignService } from '../../services/design.service';
import { ChatDebugModalComponent } from '../../components/modals/chat-debug-modal/chat-debug-modal.component';
import { ChatService } from '../../services/chat.service';
import { ChatSettingsModalComponent } from '../../components/modals/chat-settings-modal/chat-settings-modal.component';
import { ChatbotUnicredit } from 'src/app/classes/chatbot.unicredit';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  //@Output() switchChat = new EventEmitter<any>();
  orchestrateSubscription: Subscription;
  botRestoreSubscription: Subscription;

  journeys: Array<any> = [];
  openChat: boolean = false;
  botSession: ChatbotUnicredit;

  constructor(private chatService: ChatService, private modalService: NgbModal, private designService: DesignService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.orchestrateSubscription instanceof Subscription) this.orchestrateSubscription.unsubscribe();
    if (this.botRestoreSubscription instanceof Subscription) this.botRestoreSubscription.unsubscribe();
  }

  initChatSession(journeyKey: string, sessionData?: any) {
    this.botSession = new ChatbotUnicredit(new Date().getTime(), journeyKey, 'it', sessionData);
    this.sendMessage({
      event: { name: 'Welcome' }
    });
    this.botRestoreSubscription = this.botSession.onRestoreSession.subscribe(() => {
      this.initChatSession(environment.API.journeyKey, this.botSession.sessionUserData);
    });
  }

  toggleChat() {
    this.openChat = !this.openChat;
    //this.switchChat.emit(this.openChat);
    if (typeof this.botSession === 'undefined' && this.openChat) this.initChatSession(environment.API.journeyKey);
  }

  submitSpeech() {
    this.botSession.updateDialog('user', this.botSession.inputMessage);
    this.sendMessage({ userMessage: this.botSession.inputMessage});
  }

  sendMessage(params: any) {
    const data = {
      lang: this.botSession.sessionLang,
      sessionId: this.botSession.sessionId,
      query: params.userMessage || null,
      event: params.event || null,
      userData: (Object.keys(this.botSession.sessionUserData).length) ? this.botSession.sessionUserData : null
    };

    //this.orchestrateSubscription = this.chatService.sendMessage(this.botSession.sessionKey, data).subscribe((response: any) => {
    this.orchestrateSubscription = this.chatService.sendMessage(data).subscribe((response: any) => {
      //console.log('[ORCHESTRATE_RESPONSE]', response);
      this.botSession.processOrchestrateResponse(response);
    });
  }

  viewDebug() {
    const modalRef = this.modalService.open(ChatDebugModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.sessionDebug = this.botSession.sessionDebug;
  }

  editCustomData() {
    const modalRef = this.modalService.open(ChatSettingsModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.botSession = this.botSession;
  }

}
