import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) { }

  getConversationsStatistics(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
        .set('fillEmptyDate', 'true')
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/generic/conversations`, httpOptions);
  }
  
  getSingleInteractionSessions(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`, httpOptions);
  }

  getMultipleInteractionSessions(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/multi/interactions/total`, httpOptions);
  }

  getInteractionsAverageSession(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`, httpOptions);
  }

  getInteractionsAverageDuration(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
        .set('second', 'true')
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`, httpOptions);
  }

  getTopIntent(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/trand/intents/total`, httpOptions);
  }

  getTopTopics(startDate: number, endDate: number, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('startDate', startDate.toString())
        .set('endDate', endDate.toString())
    };
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/trand/topics/total`, httpOptions);
  }
  
  getTopFallback(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/trand/fallbacks/total`, httpOptions);
  }
  
  getAverageScore(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/media/dialogflow/confidence`, httpOptions);
  }
  
  getFallbackPercentage(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/percente/fallback/messages`, httpOptions);
  }
  
  getAgentAutonomy(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/agentAutonomy`, httpOptions);
  }

  getTrustedIntents(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/trustedIntents`, httpOptions);
  }

  getFeedback(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/feedback`, httpOptions);
  }

  getFeedbackAverage(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/average/feedback`, httpOptions);
  }

  getRequestForAnOperator(startDate: number, endDate: number, tags?: string, agentChannel?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    };
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    if (agentChannel !== "" && agentChannel !== null) httpOptions.params = httpOptions.params.append('agentChannel',  agentChannel);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/requestsForAnOperator`, httpOptions);
    }

  getPercentageWebsiteAndAppAnalytics(startDate: number, endDate:number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/agentChannel`, httpOptions);
  }
    
  getPercentagePopularTags(startDate: number, endDate:number): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/trand/tags/total`, httpOptions);
  }

  getPercentagePrecision(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/precision`, httpOptions);
  }  
  
  getPercentageRecall(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);

    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/percent/recall`, httpOptions);
  }

  getPrecisionAndRecall(startDate: number, endDate: number, tags?: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
      .set('startDate', startDate.toString())
      .set('endDate', endDate.toString())
    }
    if (tags) httpOptions.params = httpOptions.params.append('tags', tags);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecall`, httpOptions);
  }

  getPrecisionAndRecallForTable(params: any, pageNumber: number, pageSize: number, startDate: any, endDate: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('pageIndex', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    };

    if (Number.isInteger(params.startPrecision)) httpOptions.params = httpOptions.params.append('startPrecision', params.startPrecision);
    if (Number.isInteger(params.endPrecision)) httpOptions.params = httpOptions.params.append('endPrecision', params.endPrecision);
    if (Number.isInteger(params.startRecall)) httpOptions.params = httpOptions.params.append('startRecall', params.startRecall);
    if (Number.isInteger(params.endRecall)) httpOptions.params = httpOptions.params.append('endRecall', params.endRecall);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.status) httpOptions.params = httpOptions.params.append('status', params.status);
    if (params.topic) httpOptions.params = httpOptions.params.append('topic', params.topic);
    if (startDate) httpOptions.params = httpOptions.params.append('startDate', startDate);
    if (endDate) httpOptions.params = httpOptions.params.append('endDate', endDate);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/analytics/graphics/precisionAndRecallForTable`, httpOptions);
  }

  getPrecisionAndRecallForDownload(params: any, startDate: any, endDate: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
      responseType: 'text' as 'text'
    };

    if (Number.isInteger(params.startPrecision)) httpOptions.params = httpOptions.params.append('startPrecision', params.startPrecision);
    if (Number.isInteger(params.endPrecision)) httpOptions.params = httpOptions.params.append('endPrecision', params.endPrecision);
    if (Number.isInteger(params.startRecall)) httpOptions.params = httpOptions.params.append('startRecall', params.startRecall);
    if (Number.isInteger(params.endRecall)) httpOptions.params = httpOptions.params.append('endRecall', params.endRecall);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.status) httpOptions.params = httpOptions.params.append('status', params.status);
    if (params.topic) httpOptions.params = httpOptions.params.append('topic', params.topic);
    if (startDate) httpOptions.params = httpOptions.params.append('startDate', startDate);
    if (endDate) httpOptions.params = httpOptions.params.append('endDate', endDate);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    
    return this.http.get(`${environment.API.orchestrator_analysis}/operations/download/proficiency`, httpOptions)
  }

  getHealthCheckIntent(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };

    if(params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/intent`, httpOptions);
  }

  getHealthCheckEntity(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };

    if(params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);
    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/entity`, httpOptions);
  }

  getHealthCheckAgent(params: any, pageNumber: number, pageSize: number) {
    const httpOptions = {
      params: new HttpParams()
        .set('pageSize', pageSize.toString())
        .set('pageNumber', pageNumber.toString())
    };
    if(params.severity) {
      params.severity.forEach(item => {
        httpOptions.params = httpOptions.params.append('severity', item);
      })
    }
    if (params.errorMessage) httpOptions.params = httpOptions.params.append('errorMessage', params.errorMessage);

    return this.http.get(`${environment.API.orchestrator_analysis}/health/agent`, httpOptions);
  }

}
