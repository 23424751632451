import { Component, OnDestroy, OnInit } from '@angular/core';
import { IntentService } from 'src/app/services/intent.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-intent-settings',
  templateUrl: './intent-settings.component.html',
  styleUrls: ['./intent-settings.component.scss']
})
export class IntentSettingsComponent implements OnInit, OnDestroy {

  subscriptions = {};

  constructor(private notificationService: NotificationService, private intentService: IntentService) { }

  ngOnInit() {
  }

  exportData() {
    this.notificationService.openModal({
      title: 'Export data',
      message: 'Exporting intent data may take a few seconds, during which it will not be possible to use the application. Do you want to continue?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['exportExcel'] =this.intentService.exportExcel().subscribe()
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

}
