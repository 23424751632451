import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';

import { TagIcons } from '../../enums/tag-icons.enum';
import { Pagination } from '../../classes/pagination';
import { IntentService } from '../../services/intent.service';
import { NotificationService } from '../../services/notification.service';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';

@Component({
  selector: 'intent-tags',
  templateUrl: './intent-tags.component.html',
  styleUrls: ['./intent-tags.component.scss']
})
export class IntentTagsComponent implements OnInit {
  @ViewChild('tagForm', { static: false }) private tagForm: NgForm;
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  subscriptions: Array<Subscription> = [];
  intentsPagination: Pagination = new Pagination();
  tagIcons = TagIcons;

  filter: any = {
    sortBy: 'name:asc'
  };
  tags: Array<any> = [];
  tagsByType: any;
  tagsCategoryGroup: any;
  intents: Array<any> = [];
  intentSelected: any;
  newTag: any;
  sidebarOpened: boolean = false;

  tagSuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getTagsSuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
    )

  tagSuggestionFormat = (value: any) => value.name;
  tagSuggestionSelect = (value: any) => value.name;

  chooseTag($event: any) {
    let data = this.intentSelected;
    data.tags.push($event.item);

    this.updateTagsIntent(data);
  }

  categorySuggestions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.intentService.getCategorySuggestions(term).pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
    )

  constructor(private intentService: IntentService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.filter.tag = '';
    this.getTags();
    this.searchIntent(1);
  }

  getTags() {
    this.subscriptions['Tags'] = this.intentService.getTags({isLogic: false}).subscribe((response: any) => {
      //console.log('[TAGS]', response);
      this.tags = response;
      this.tagsByType = this.groupTagStatus();
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchIntent(this.intentsPagination.currentPage);
  }

  groupTagStatus() {
    return this.tags.reduce((objectsByKeyValue, obj) => {
      if(obj.type !== "SYSTEM") {
        if(!objectsByKeyValue[obj.type]) {
          objectsByKeyValue[obj.type] = [];
        }
       objectsByKeyValue[obj.type].push(obj);
      }
      return objectsByKeyValue;
   }, {});
  }

  synchIntents() {
    this.notificationService.openModal({
      title: 'Data update',
      message: 'The data update may take few seconds, during which it will not be possible to use the application. Do you want to continue?',
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['SynchIntents'] = this.intentService.synchIntents({}).subscribe((response: any) => {
        this.searchIntent(1);
      });
    });
  }

  updateTagsIntent(intent: any) {
    this.subscriptions['UpdateTagsIntent'] = this.intentService.updateTagsIntent(intent).subscribe((response: any) => {
      //console.log('[UPDATE_TAGS_INTENT]', response);
      this.newTag = null;
      this.searchIntent(this.intentsPagination.currentPage);
      this.selectTag(intent);
    });
  }

  searchIntent(pageSelected: number) {
    this.intentsPagination.onSelectPage(pageSelected);

    const params = {
      name: this.filter.intentName || null,
      tag: this.filter.tag || null,
      sortBy: this.filter.sortBy || null

    };

    this.subscriptions['Intents'] = this.intentService.getIntentsTags(params, this.intentsPagination.getPageIndex(), this.intentsPagination.pageSize).subscribe((response: any) => {
      //console.log('[INTENTS]', response);
      this.intents = response.content;
      this.intentsPagination.updateTotals(response.totalElements);
    });
  }

  selectTag(intent: any) {
    //console.log('[INTENT_SELECTED]', intent);
    this.sidebarOpened = true;
    this.intentSelected = {};
    Object.assign(this.intentSelected, intent);
    this.tagsCategoryGroup = this.groupCategoriesTag(intent.tags);
  }

  groupCategoriesTag(tags: Array<any>): any {
    let tagsGrouped = tags.reduce((a, b) => {
      (a[b['type']] = a[b['type']] || []).push(b);
      return a;
    }, {})

    for (const key in tagsGrouped) {
      if (tagsGrouped.hasOwnProperty(key)) {
        tagsGrouped[key] = tagsGrouped[key].filter(tag => tag.name !== 'untrusted');
        if (tagsGrouped[key].length === 0) delete tagsGrouped[key];
      }
    }
    return tagsGrouped;
  }

  createTag() {
    if (this.tagForm.invalid) return;

    let data = this.intentSelected;
    data.tags.push({...this.newTag,
      logicTag: (this.newTag.type === 'Topic')
    });

    this.updateTagsIntent(data);
  }

  removeTag(tagName: string) {
    let data = this.intentSelected;
    data.tags = data.tags.filter(tag => tag.name !== tagName);

    this.updateTagsIntent(data);
  }

  resetFilters() {
    this.filter = {
      tag: ''
    };
    this.thead.forEach((th: any) => th.direction = '');
    this.searchIntent(1);
  }

  closeSidebar() {
    this.sidebarOpened = false;
    this.intentSelected = {};
  }
}
