import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss']
})
export class SimpleButtonComponent implements OnInit {

  @Input() form: FormGroup;

  @Output() delete = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  remove() {
    this.delete.emit();
  }

}
