import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'chat-debug-modal',
  templateUrl: './chat-debug-modal.component.html',
  styleUrls: ['./chat-debug-modal.component.scss']
})
export class ChatDebugModalComponent implements OnInit {
  @Input() sessionDebug: any;

  interaction: Array<any> = [{}, {}];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {}

  /* abort(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  } */

}
