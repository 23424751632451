import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../../classes/pagination';
import { TableSortDirective } from '../../../directives/table-sort.directive';
import { DialogService } from '../../../services/dialog.service';
import { RatingModalComponent } from '../../../components/modals/rating-modal/rating-modal.component';

import { environment } from '../../../../environments/environment';
import { subscribeOn } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

declare let window: any;

@Component({
  selector: 'conversational-history',
  templateUrl: './conversational-history.component.html',
  styleUrls: ['./conversational-history.component.scss']
})
export class ConversationalHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;
  @ViewChild('conversationalHistoryForm', {static: false}) conversationalHistoryForm: NgForm;

  interactionsSubscription: Subscription;
  exportCSVSubscription: Subscription;
  interactionsPagination: Pagination = new Pagination();

  pageTarget: number = 1;
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number;

  filter: any = {};
  interactions: Array<any> = [];
  currentDate = new Date();
  rangePickerOptions: any = {
    required: true,
    readonly: true,
    maxDate: {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    }
  };
  
  AgentChannelsType = new Map([
    [0, 'Not specified'],
/*     [1, 'App'],
    [2, 'Website'], */
    [3, 'App Not Logged'],
    [4, 'App Logged'],
    [5, 'Website Not Logged'],
    [6, 'Website Logged'],
    [7, 'Kiosk Not logged'],
    [8, 'Kiosk Logged'],
    [11, 'App Business Not Logged'],
    [12, 'App Business Logged'],
  ]);

  constructor(private modalService: NgbModal, private dialogService: DialogService) {
    let currentDateminus49 = new Date();
    currentDateminus49.setDate(currentDateminus49.getDate() - 49);
    this.rangePickerOptions.minDate = {
      day: currentDateminus49.getDate(),
      month: currentDateminus49.getMonth() + 1,
      year: currentDateminus49.getFullYear()
    }
    let currentDateFrom = new Date();
    currentDateFrom.setDate(currentDateFrom.getDate() - 7);
    this.filter.fromDate = {
      day: currentDateFrom.getDate(),
      month: currentDateFrom.getMonth() + 1,
      year: currentDateFrom.getFullYear()
    };
    this.filter.toDate = {...this.rangePickerOptions.maxDate};
  }

  ngOnInit() {
    this.filter.agentChannel = '';
    this.searchInteractions(1);
  }

  ngOnDestroy() {
    this.interactionsSubscription.unsubscribe();
    if (this.exportCSVSubscription) this.exportCSVSubscription.unsubscribe();
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.fromDate = $event.date;
    if ($event.type === 'to') this.filter.toDate = $event.date;
  }

  viewConversation(sessionId: string) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.sessionId = sessionId;
  }

  searchInteractions(pageSelected: number) {
    this.interactionsPagination.onSelectPage(pageSelected);
    
    const params = {
      sessionId: this.filter.sessionId,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : null,
      dfIntentName: this.filter.intent || null,
      userQuery: this.filter.userQuery || null,
      sortBy: this.filter.sortBy || null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null


    };

    this.interactionsSubscription = this.dialogService.getInteractionHistory(params, this.interactionsPagination.getPageIndex(), this.interactionsPagination.pageSize).subscribe((response: any) => {
      //console.log('[INTERACTIONS]', response);
      this.interactions = response.data;
      this.interactionsPagination.updateTotals(response.totalElements);
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchInteractions(this.interactionsPagination.currentPage);
  }

  exportCSV(){
    const params = {
      sessionId: this.filter.sessionId,
      startDate: this.filter.fromDate ? new Date(this.filter.fromDate.year, this.filter.fromDate.month - 1, this.filter.fromDate.day, 0, 0, 0).getTime() : new Date(2000,1,1,0,0,0).getTime(),
      endDate: this.filter.toDate ? new Date(this.filter.toDate.year, this.filter.toDate.month - 1, this.filter.toDate.day, 23, 59, 59).getTime() : new Date(2100,1,1,0,0,0).getTime(),
      dfIntentName: this.filter.intent || null,
      userQuery: this.filter.userQuery || null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null
    };
    this.exportCSVSubscription = this.dialogService.getCSV(params).subscribe((response: any) => {
      const elementDom = document.createElement('a'); 
      const blob = new Blob([response], { type: 'text/csv' });
      const url= window.URL.createObjectURL(blob);
      elementDom.href = url ;
      elementDom.download = 'export.csv';
      elementDom.click();
      URL.revokeObjectURL(url);
      
    })
  }
}
