import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionHandlerService } from 'src/app/services/session-handler.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  stretchMenu: boolean;
  openChat: boolean;

  constructor(private sessionHandlerService: SessionHandlerService, private authenticationService: AuthenticationService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.sessionHandlerService.onRefreshSession().subscribe(
      () => {
        this.authenticationService.refreshToken().subscribe(
          () => {},
          () => {
            this.authenticationService.eraseSession();
            this.notificationService.showToast(`Session expired, please login again`, { type: 'warning' });
          }
        );
      }
    )
  }

  toggleMenu($event) {
    this.stretchMenu = $event;
  }

}
