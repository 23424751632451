import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { AnalyticsService } from '../../../services/analytics.service';

declare let google: any;

@Component({
  selector: 'analytic-sessions',
  templateUrl: './analytic-sessions.component.html',
  styleUrls: ['./analytic-sessions.component.scss']
})
export class AnalyticSessionsComponent implements OnInit, OnDestroy {
  @Input() filter?: any = {};
  @Input() processChartData: any;

  subscriptions: Array<Subscription> = [];

  conversationsStatistics: any;
  singleInteractionSessions: number;
  multiInteractionSessions: number;
  interactionsAverageSession: number;
  interactionsAverageDuration: number;
  interactionsPercentageWebsiteAndAppAnalytics: any;
  popularTopicsAnalytics: any;
  websitePercentage: number;
  appPercentage: number;
  undefinedPercentage: number;

  tags: Array<any> = [];

  toolTipsSession: any = {
    totalSessions: "A group of interactions (i.e. combined intents) between the chatbot and the end-user.",
    singleSession: "Sessions where users only trigger the Welcome intent.",
    multiSession: "Sessions where users trigger more then one intent.",
    messagesPerSession: "Average number of interactions (combined intents) throughout recorded sessions.",
    sessionDuration: "Average session duration (in minutes), starting from the first to the last query of the user.",
    channels: "Source channel of user's interactions.",
    popularTopics: "Most requested topics by users within conversations."
  };

  percentageVal = [];

  constructor(private analyticsService: AnalyticsService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    this.getStatistics();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics() {
    const params = {
      startDate: this.filter.period.fromDate ? new Date(this.filter.period.fromDate.year, this.filter.period.fromDate.month - 1, this.filter.period.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.period.toDate ? new Date(this.filter.period.toDate.year, this.filter.period.toDate.month - 1, this.filter.period.toDate.day, 23, 59, 59).getTime() : null,
      tags: this.filter.intentTag || null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null
    };
    this.getConversationsStatistics(params);
    this.getSingleInteractionSessions(params);
    this.getMultipleInteractionSessions(params);
    this.getInteractionsAverageSession(params);
    this.getInteractionsAverageDuration(params);
    this.getInteractionsPercentageWebsiteAndAppAnalytics(params);
    this.getPopularTopicsAnalytics(params);
  }

  getConversationsStatistics(params: any) {
    this.subscriptions['ConversationsStatistics'] = this.analyticsService.getConversationsStatistics(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.conversationsStatistics = {
        total: response.multiObject.total.value,
        chartData: this.processChartData('ConversationsStatistics', response.multiObject.line.lineChart)
      };
      this.drawSessionsChart();
    });
  }

  getSingleInteractionSessions(params: any) {
    this.subscriptions['SingleInteractionSessions'] = this.analyticsService.getSingleInteractionSessions(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.singleInteractionSessions = response.singleResult.value;
    });
  }

  getMultipleInteractionSessions(params: any) {
    this.subscriptions['MultipleInteractionSessions'] = this.analyticsService.getMultipleInteractionSessions(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.multiInteractionSessions = response.singleResult.value;
    });
  }

  getInteractionsAverageSession(params: any) {
    this.subscriptions['InteractionsAverageSession'] = this.analyticsService.getInteractionsAverageSession(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.interactionsAverageSession = response.singleResult.value;
    });
  }

  getInteractionsAverageDuration(params: any) {
    this.subscriptions['InteractionsAverageDuration'] = this.analyticsService.getInteractionsAverageDuration(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.interactionsAverageDuration = response.singleResult.value;
    });
  }

  getInteractionsPercentageWebsiteAndAppAnalytics(params: any){
    this.subscriptions['InteractionsPercentageWebsiteAndAppAnalytics'] = 
    this.analyticsService.getPercentageWebsiteAndAppAnalytics(params.startDate, params.endDate)
    .subscribe((response: any) => {
      this.interactionsPercentageWebsiteAndAppAnalytics  = {
        website: response.singleResult.find( ({ name }) => name === 'WEBSITE_ANTELOGIN' ),
        gimb: response.singleResult.find( ({ name }) => name === 'GIMB' ),
        appAnteLogin: response.singleResult.find( ({ name }) => name === 'APP_ANTELOGIN' ),
        appPostLogin: response.singleResult.find( ({ name }) => name === 'APP_POSTLOGIN' ),
        kioskAnteLogin: response.singleResult.find( ({ name }) => name === 'CHIOSCO_ANTELOGIN' ),
        kioskPostLogin: response.singleResult.find( ({ name }) => name === 'CHIOSCO_POSTLOGIN' ),
        appBusinessAnteLogin: response.singleResult.find( ({ name }) => name === 'APP_BUSINESS_ANTELOGIN' ),
        appBusinessPostLogin: response.singleResult.find( ({ name }) => name === 'APP_BUSINESS_POSTLOGIN' ),
        undefined: response.singleResult.find( ({ name }) => name === 'UNDEFINED' )
      };
      this.drawAnalyticsChart();

    })

  }
  getPopularTopicsAnalytics(params: any) {
    this.subscriptions['PopularTopicsAnalytics'] = this.analyticsService.getPercentagePopularTags( params.startDate, params.endDate).subscribe((response: any) => {
        this.popularTopicsAnalytics = this.processChartData('PopularTopicsAnalytics', response.singleResult)
      this.drawPopularTopics();
      })
  }

/*   getPopularTopicsAnalytics(params: any) {
        this.popularTopicsAnalytics = this.processChartData('PopularTopicsAnalytics', []) //response.singleResult
        this.drawPopularTopics();
  } */

  drawSessionsChart() {
    google.charts.setOnLoadCallback(() => {
      let data = google.visualization.arrayToDataTable(this.conversationsStatistics.chartData);

      let options = {
        curveType: 'none',
        legend: { position: 'none' },
        hAxis: {
          format: 'dd MMM y'
        }
      };

      let chart = new google.visualization.LineChart(document.getElementById('sessions_chart'));

      chart.draw(data, options);
    });
  }

  drawAnalyticsChart() {
    google.charts.setOnLoadCallback(() => {

      this.percentageVal = [
        ['Website Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.website.perc, '#647DFC'],
        ['Website Logged', this.interactionsPercentageWebsiteAndAppAnalytics.gimb.perc, '#F5008C' ],
        ['App Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.appAnteLogin.perc, '#33032F'],
        ['App Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appPostLogin.perc, '#531253'],
        ['Kiosk Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.kioskAnteLogin.perc, '#BDB76B'],
        ['Kiosk Logged', this.interactionsPercentageWebsiteAndAppAnalytics.kioskPostLogin.perc, '#9400D3'],
        ['App Business Not Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appBusinessAnteLogin.perc, '#B33951'],
        ['App Business Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appBusinessPostLogin.perc, '#006DAA'],
        ['Not specified', this.interactionsPercentageWebsiteAndAppAnalytics.undefined.perc, '#FF7733']
      ]

      let data = google.visualization.arrayToDataTable([
        ['Channel', 'Value'],
        ['Website Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.website.number ],
        ['Website Logged', this.interactionsPercentageWebsiteAndAppAnalytics.gimb.number ],
        ['App Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.appAnteLogin.number],
        ['App Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appPostLogin.number],
        ['Kiosk Not logged', this.interactionsPercentageWebsiteAndAppAnalytics.kioskAnteLogin.number],
        ['Kiosk Logged', this.interactionsPercentageWebsiteAndAppAnalytics.kioskPostLogin.number],
        ['App Business Not Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appBusinessAnteLogin.number],
        ['App Business Logged', this.interactionsPercentageWebsiteAndAppAnalytics.appBusinessPostLogin.number],
        ['Not specified', this.interactionsPercentageWebsiteAndAppAnalytics.undefined.number]
      ]);

      let options = {
        pieHole: 0.8,
        legend: 'none',
        colors: ['#647DFC', '#F5008C','#33032F', '#531253', '#BDB76B', '#9400D3', '#B33951', '#006DAA', '#FF7733'],
        pieSliceText: 'none',
        titlePosition: 'top',
        height: 250,
        chartArea: 
        {
          'width': '80%', 
          'height': '80%'
        }
      };
      let chart = new google.visualization.PieChart(document.getElementById('channel_chart'));

      chart.draw(data, options);
    });
  }

  drawPopularTopics() {
    google.charts.setOnLoadCallback(() => {
      let data;

      let options: any = {
        pieHole: 0.8,
        legend: {
          position: 'right',
          alignment: 'center', 
          textStyle: { 
            fontSize: 12,
          }
        },
        colors: [],
        pieSliceText: 'none',
        titlePosition: 'top',
        height: 250,
        chartArea: {
          left: 30,
          width: '93%', 
          height: '93%'
        }
      };

      if (this.isEmptyChart('popularTopics')) {
        data = new google.visualization.DataTable();
        data.addColumn('string', 'Tags');
        data.addColumn('number', 'Value');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows([
          ['No data', 100, 'No data']
        ]);
        options.colors = ['#E3E3E3'];
        options.legend = 'none';
        options.chartArea = {
          left: 0,
          width: '80%', 
          height: '80%'
        }
      } else {
        data = google.visualization.arrayToDataTable(this.popularTopicsAnalytics);
        options.colors = ['#9816F4', '#1DC9B7', '#FD397A', '#00AFF0', '#FFB822', '#343A40'];
      }

      let chart = new google.visualization.PieChart(document.getElementById('populartopics_charts'));

      chart.draw(data, options);
    });
  }

  isEmptyChart(chartType: 'popularTopics' | 'analyticsChart'): boolean {
    if (chartType === 'analyticsChart') {
      return !(this.interactionsPercentageWebsiteAndAppAnalytics.website.number > 0 || this.interactionsPercentageWebsiteAndAppAnalytics.app.number > 0 || this.interactionsPercentageWebsiteAndAppAnalytics.undefined.number > 0);
    } else if (chartType === 'popularTopics') {
      return (this.popularTopicsAnalytics && this.popularTopicsAnalytics.length === 1);
    }
  }

  getColor(color) {
    return {
      'color': color
    }
  }

}
