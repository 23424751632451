
import { Injectable } from '@angular/core';

import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {

    fromModel(value: string): NgbDateStruct {
        let result: NgbDateStruct = null;
        let date = new Date(parseInt(value));
        //console.log('[VALUE]', value);
        //console.log('[DATE]', date);
        if (value) {
            result = {
                day: date.getDate(),
                month: date.getMonth()+1,
                year: date.getFullYear()
            };
        }
        return result;
    }

    toModel(date: NgbDateStruct): string {
        let result: string = null;
        //console.log('[DATE]', date);
        if (date) {
            result = (new Date(`${date.year}/${date.month}/${date.day}`).getTime()).toString();
        }
        return result;
    }
}
