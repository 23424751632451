import { Chatbot } from "./chatbot";

export class ChatbotUnicredit extends Chatbot {

    constructor(chatbotId: number, chatbotKey: string, chatbotLang: string, chatbotUserData?: any) {
        super(chatbotId, chatbotKey, chatbotLang);
        this.sessionUserData = chatbotUserData || {
            Custom01: 2,
            Custom07: 0,
            TransferSkillBot: 'default_ita'
        };
    }
}
