import { registerLocaleData } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { DateParserFormatter } from "./classes/date-parser-formatter";
import { DatepickerAdapter } from "./classes/datepicker-adapter";
import { SpinnerLoaderComponent } from "./components/utility/spinner-loader/spinner-loader.component";
import { SpinnerLoaderDirective } from "./directives/spinner-loader.directive";
import { httpInterceptorProviders } from './http-interceptors';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it');

@NgModule({
    declarations: [
        SpinnerLoaderDirective,
        SpinnerLoaderComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule
    ],
    entryComponents: [
        SpinnerLoaderComponent
    ],
    providers: [
        httpInterceptorProviders,
        CookieService,
        { provide: LOCALE_ID, useValue: 'it' },
        { provide: NgbDateAdapter, useClass: DatepickerAdapter },
        { provide: NgbDateParserFormatter, useClass: DateParserFormatter }
    ],
    exports: [
        SpinnerLoaderDirective,
        SpinnerLoaderComponent,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule
    ]
})
export class SharedModule {}
