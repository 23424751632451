import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { AnalyticsService } from '../../../services/analytics.service';

declare let google: any;

@Component({
  selector: 'analytic-intents',
  templateUrl: './analytic-intents.component.html',
  styleUrls: ['./analytic-intents.component.scss']
})
export class AnalyticIntentsComponent implements OnInit, OnDestroy {
  @Input() filter?: any = {};
  @Input() processChartData: any;

  subscriptions: Array<Subscription> = [];

  topFallback: Array<Array<any>> = [];
  topIntent: Array<Array<any>> = [];
  fallbackPercentage: number;
  requestForAnOperator: number;
  tags: Array<any> = [];
  
  toolTipsIntents: any = {
    mostTriggeredIntents: "Top 5 of the most used intents among all sessions.",
    mostTriggeredTopics: "Top 5 of the most used topics among all sessions.",
    mostTriggeredFallbacks: "When the virtual agent doesn't recognize an end-user expression.",
    fallbackPercentage: "How often the virtual agent could not understand the users’ requests.",
    operatorRequest: "How many times the user has expressed an explicit desire to talk to an operator."
  };

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    this.getStatistics();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics() {
    const params = {
      startDate: this.filter.period.fromDate ? new Date(this.filter.period.fromDate.year, this.filter.period.fromDate.month - 1, this.filter.period.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.filter.period.toDate ? new Date(this.filter.period.toDate.year, this.filter.period.toDate.month - 1, this.filter.period.toDate.day, 23, 59, 59).getTime() : null,
      tags: this.filter.intentTag || null,
      agentChannel: this.filter.agentChannel || this.filter.agentChannel === 0 ? this.filter.agentChannel : null

    };
    this.getTopFallback(params);
    this.getFallbackPercentage(params);
    this.getRequestForAnOperator(params);
    this.getTopIntent(params);
    this.getTopTopics(params);
  }

  getTopFallback(params: any) {
    this.subscriptions['TopFallback'] = this.analyticsService.getTopFallback(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.topFallback = this.processChartData('TopFallback', response.singleResult.barChart);
      this.drawFallbackChart();
    });
  }

  getFallbackPercentage(params: any) {
    this.subscriptions['FallbackPercentage'] = this.analyticsService.getFallbackPercentage(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.fallbackPercentage = response.singleResult.value;
    });
  }

  getRequestForAnOperator(params: any) {
    this.subscriptions['RequestForAnOperator'] = this.analyticsService.getRequestForAnOperator(params.startDate, params.endDate, params.tags, params.agentChannel)
    .subscribe((response: any)=> {
      this.requestForAnOperator = response.singleResult;
    });
  }

  getTopIntent(params: any) {
    this.subscriptions['TopIntent'] = this.analyticsService.getTopIntent(params.startDate, params.endDate, params.tags, params.agentChannel).subscribe((response: any) => {
      this.topIntent = this.processChartData('TopIntent', response.singleResult.barChart);
      this.drawIntentTopicChart('intents_chart');
    });
  }

  getTopTopics(params: any) {
    this.subscriptions['TopTopics'] = this.analyticsService.getTopTopics(params.startDate, params.endDate, params.agentChannel).subscribe((response: any) => {
      this.topIntent = this.processChartData('TopTopics', response.singleResult.barChart);
      this.drawIntentTopicChart('topics_chart');
    });
  }

  drawIntentTopicChart(id: string) {
    google.charts.setOnLoadCallback(() => {
      let data = google.visualization.arrayToDataTable(this.topIntent);

      let options = {
        legend: { position: 'none' },
        chartArea: { height: '85%'}
      };

      let chart = new google.visualization.BarChart(document.getElementById(id));
      chart.draw(data, options);
    });
  }

  drawFallbackChart() {
    google.charts.setOnLoadCallback(() => {
      let data = google.visualization.arrayToDataTable(this.topFallback);

      let options = {
        legend: { position: 'none' },
      };

      let chart = new google.visualization.BarChart(document.getElementById('fallback_chart'));
      chart.draw(data, options);
    });
  }

}
