import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesignService {

  constructor(private http: HttpClient) { }

  getJourneysList(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/journey/getAll`);
  }

}
