import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlaceholderTypes } from '../enums/placeholder-types.enum';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  createPlaceholderForm() {
    return this.fb.group({
      placeholder: this.fb.control('', Validators.required),
      type: this.fb.control('text'),
      textGroup: this.fb.group({
        value: this.fb.control('', Validators.required)
      }),
      payloadGroup: this.fb.group({
        type: this.fb.control(PlaceholderTypes.BASIC_CARD, Validators.required),
        basicCard: this.getGraphicsGroup(this.getBasicCardGroup()),
        carouselCard: this.getGraphicsGroup(this.getBasicCardGroup()),
        simpleChip: this.getGraphicsGroup(this.getsimpleChipGroup()),
        chipCarousel: this.getGraphicsGroup(this.getsimpleChipGroup()),
        link: this.getGraphicsGroup(this.getLinkGroup()),
        button: this.getGraphicsGroup(this.getButtonGroup())
      })
    })
  }

  getGraphicsGroup(item: AbstractControl) {
    return this.fb.group({
      graphics: this.fb.group({
        type: this.fb.control('', Validators.required),
        disableChat: this.fb.control(false),
        items: this.fb.array([item])
      })
    })
  }

  getBasicCardGroup() {
    return this.fb.group({
      image: this.fb.control(''),
      title: this.fb.control(''),
      subtitle: this.fb.control(''),
      layout: this.fb.control(''),
      body: this.fb.control(''),
      url: this.fb.control(''),
      target: this.fb.control(''),
      linkButton: this.fb.group({
        text: this.fb.control(''),
        url: this.fb.control(''),
        url_app: this.fb.control('')
      }),
      placeholderKey: this.fb.control(null)
    })
  }

  getsimpleChipGroup() {
    return this.fb.group({
        title: this.fb.control(''),
        body: this.fb.control(''),
        icon: this.fb.control(''),
        style: this.fb.control(''),
        placeholderKey: this.fb.control(null)
    })
  }

  getLinkGroup() {
    return this.fb.group({
        title: this.fb.control(''),
        url: this.fb.control(''),
        linkButton: this.fb.group({
          text: this.fb.control(''),
          url: this.fb.control(''),
          url_app: this.fb.control('')
        }),
    })
  }

  getButtonGroup() {
    return this.fb.group({
        title: this.fb.control(''),
        body: this.fb.control(''),
        style: this.fb.control(''),
    })
  }

  disableGroupElementBut(group: FormGroup, notToDisable?) {
    Object.keys(group.controls).forEach(nameControl => {
      if (!notToDisable || notToDisable !== nameControl ) {
        group.get(nameControl).disable();
      }
    });
  }

  getPlaceholder(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
    };

    if (params.placeholder) httpOptions.params = httpOptions.params.append('key', params.placeholder);
    if (params.type) httpOptions.params = httpOptions.params.append('type', params.type);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_configurator}/placeholder`, httpOptions);
  }

  getPayloadTypes(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/placeholder/payloadTypes`);
  }

  createPlaceholder(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/placeholder`, data);
  }

  updatePlaceholder(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/placeholder`, data);
  }


  deletePlaceholder(placeholder: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('key', placeholder)
    };

    return this.http.delete(`${environment.API.orchestrator_configurator}/placeholder`, httpOptions);
  }
}
