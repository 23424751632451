import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../../services/authentication.service';
import { SupportModalComponent } from '../../components/modals/support-modal/support-modal.component';

@Component({
  selector: 'console-header',
  templateUrl: './console-header.component.html',
  styleUrls: ['./console-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsoleHeaderComponent implements OnInit {
  @Output() switchMenu = new EventEmitter<any>();
  @Input() stretchMenu: boolean;

  user: any;

  constructor(private authenticationService: AuthenticationService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.user = this.authenticationService.user;
  }

  toggleMenu(stretchStatus: boolean) {
    this.stretchMenu = !stretchStatus;
    this.switchMenu.emit(this.stretchMenu);
  }

  logout() {
    this.authenticationService.logout({}).toPromise();
  }

  modalSupport() {
    this.modalService.open(SupportModalComponent, {
      size: 'lg'
    });
  }

}
