import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { IntentService } from 'src/app/services/intent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dictionary-modal',
  templateUrl: './dictionary-modal.component.html',
  styleUrls: ['./dictionary-modal.component.scss']
})
export class DictionaryModalComponent implements OnInit {

  @Input() word: any = {
    synonyms: []
  };
  @Input() types = [];
  @Output() onUpdateResults = new EventEmitter<any>();

  newSynonym;
  formSubmitted = false;

  constructor(public activeModal: NgbActiveModal, private dictionaryService: DictionaryService) { }

  ngOnInit() {
  }

  addSynonym(event) {
    if (event.key === 'Enter' && this.newSynonym) {
      this.word.synonyms.push(this.newSynonym);
      this.newSynonym = null;
    }
  }

  deleteSynonym(index) {
    this.word.synonyms.splice(index, 1);
  }

  save() {
    if (this.newSynonym) this.word.synonyms.push(this.newSynonym);
    if (!this.word.id) {
      this.word.journeyApiKey = environment.API.journeyKey;
      this.dictionaryService.createDictionary(this.word).subscribe(() => {
        this.onUpdateResults.emit();
        this.activeModal.close();
      })
    } else {
      this.dictionaryService.updateDictionary(this.word).subscribe(() => {
        this.onUpdateResults.emit();
        this.activeModal.close();
      })
    }
  }

}
