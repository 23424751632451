import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AdminService } from '../../../services/admin.service';
import { Pagination } from '../../../classes/pagination';
import { TopicsModalComponent } from '../../modals/topics-modal/topics-modal.component';

@Component({
  selector: 'training-team',
  templateUrl: './training-team.component.html',
  styleUrls: ['./training-team.component.scss']
})
export class TrainingTeamComponent implements OnInit, OnDestroy {
  @ViewChild('trainerFilter', { static: true }) private trainerFilter: NgForm;

  trainersSubscriptions: Subscription;
  trainers: Array<any> = [];
  trainersPagination: Pagination = new Pagination();

  constructor(private modalService: NgbModal, private adminService: AdminService) { }
  
  ngOnInit() {
    this.getTrainers();
  }

  ngOnDestroy() {
    if (this.trainersSubscriptions instanceof Subscription) this.trainersSubscriptions.unsubscribe();
  }

  getTrainers() {
    this.trainersSubscriptions = this.adminService.getTrainerTags().subscribe((response: any) => {
      this.trainers = response;
    });
  }

  editTopics(trainer: any) {
    const modalRef = this.modalService.open(TopicsModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.trainer = trainer;
    modalRef.componentInstance.onUpdateTags.subscribe($event => this.getTrainers());
  }
}