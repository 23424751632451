
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService) {  }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status !== 401 && error.error !== 'SESSION') {
                    this.notificationService.openModal({
                        title: `Service error - ${error.status}`,
                        message: `<div class="alert alert-danger mb-0" role="alert">${(error && error.error.message ? error.error.message  : 'Generic Error')}</div>`,
                        type: 'danger'
                    });
                }
                return throwError(error);
            })
        );
    }
}
