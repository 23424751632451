import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from 'src/app/classes/pagination';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { IntentService } from 'src/app/services/intent.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DictionaryModalComponent } from '../modals/dictionary-modal/dictionary-modal.component';

@Component({
  selector: 'dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.scss']
})
export class DictionaryComponent implements OnInit, OnDestroy {

  filter: any = {
    type: 'TRANSLATE_IGNORE'
  };
  dictionaryData = [];
  subscriptions = {};
  types = [];
  resultsPagination: Pagination = new Pagination();
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  constructor(private dictionaryService: DictionaryService, private modalService: NgbModal, private notificationService: NotificationService) { }

  ngOnInit() {
    this.searchDictionary(1);
    this.getDictionaryTypes();
  }

  openWordModal(dictionaryElement?) {
    const modalRef = this.modalService.open(DictionaryModalComponent, {
      size: 'lg'
    });
    if (dictionaryElement) {
      modalRef.componentInstance.word = dictionaryElement;
    }
    modalRef.componentInstance.types = this.types;
    modalRef.componentInstance.onUpdateResults.subscribe(() => {
      this.searchDictionary(this.resultsPagination.currentPage);
    })
  }
  
  searchDictionary(pageSelected: number) {
    this.resultsPagination.onSelectPage(pageSelected);
    let params = {
      type: this.filter.type,
      value: this.filter.value,
      sortBy: this.filter.sortBy
    }
    this.subscriptions['Dictionary'] = this.dictionaryService.getDictionary(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize).subscribe(data => {
      this.dictionaryData = data.content.map(el => {
        return {...el, synonymsString: el.synonyms.join(', ')}
      });
      this.resultsPagination.updateTotals(data.totalElements);
    })
  }

  delete(word) {
    this.notificationService.openModal({
      title: 'Delete word',
      message: `Are you sure you want to delete the word ${word.value} and all associated synonyms?`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;
      this.dictionaryService.deleteDictionary(word.id).subscribe(() => {
        this.searchDictionary(this.resultsPagination.currentPage);
      })
    });
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchDictionary(this.resultsPagination.currentPage);
  }

  private getDictionaryTypes() {
    this.dictionaryService.getDictionaryTypes().subscribe((data: any[]) => {
      this.types = data;
    })
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

}
