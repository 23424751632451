import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';

import { IntentService } from '../../../services/intent.service';

@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss']
})
export class AnalyticsFilterComponent implements OnInit {
  @Input() filter?: any = {};
  @Input() showFilterChannel?: boolean;
  @Output() getStatistics = new EventEmitter<any>();
  @ViewChild('analyticForm', {static: false}) analyticForm: NgForm;

  subscriptions: Array<Subscription> = [];

  AgentChannelsType = new Map([
    [0, 'Not specified'],
/*     [1, 'App'],
    [2, 'Website'], */
    [3, 'App Not Logged'],
    [4, 'App Logged'],
    [5, 'Website Not Logged'],
    [6, 'Website Logged'],
    [7, 'Kiosk Not logged'],
    [8, 'Kiosk Logged'],
    [11, 'App Business Not Logged'],
    [12, 'App Business Logged'],
  ]);
  
  tags: Array<any> = [];
  tagsByType: any;
  currentDate = new Date();
  rangePickerOptions: any = {
    required: true,
    readonly: true,
    maxDate: {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear()
    }
  };
  constructor(private intentService: IntentService) { }

  ngOnInit() {
    this.getTags();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getTags() {
    this.subscriptions['Tags'] = this.intentService.getTags({isLogic: false}).subscribe((response: any) => {
      this.tags = response;
      this.tagsByType = this.groupTagStatus();
    });
  }

  groupTagStatus() {
    return this.tags.reduce((objectsByKeyValue, obj) => {
      if(obj.type !== 'SYSTEM') {
        if(!objectsByKeyValue[obj.type]) {
          objectsByKeyValue[obj.type] = [];
        }
       objectsByKeyValue[obj.type].push(obj);
      }
      return objectsByKeyValue;
   }, {});
  }

  updateDate($event) {
    if ($event.type === 'from') this.filter.period.fromDate = $event.date;
    if ($event.type === 'to') this.filter.period.toDate = $event.date;
  }

  getStats() {
    this.getStatistics.emit();
  }
}
