import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss']
})
export class RatingModalComponent implements OnInit, OnDestroy {
  @Input() sessionId: string;
  
  interactionsSubscription: Subscription;

  interactions: Array<any> = [];

  constructor(public activeModal: NgbActiveModal, private dialogService: DialogService) { }

  ngOnInit() {
    this.getInteractions(this.sessionId);
  }

  ngOnDestroy() {
    this.interactionsSubscription.unsubscribe();
  }

  /* abort(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  } */

  getInteractions(sessionId: string) {
    this.interactionsSubscription = this.dialogService.getInteractions(sessionId).subscribe((response: any) => {
      //console.log('[INTERACTIONS]', response);
      this.interactions = response.data;
    });
  }

  fallbackCount(): number {
    let counter = 0;
    this.interactions.forEach((interaction: any) => {
      if (interaction.fallback) counter++;
    });
    return counter;
  }

  averageConversationScore(): number {
    return this.interactions.reduce((a, b) => {
      return (isNaN(a.operatorRatings) ? 0 : a.operatorRatings) + (isNaN(b.operatorRatings) ? 0 : b.operatorRatings);
    }, 0);
  }

}
