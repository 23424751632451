import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Pagination } from 'src/app/classes/pagination';
import { TableSortDirective } from 'src/app/directives/table-sort.directive';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, OnDestroy {

  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  filterFor: 'issue' | 'intent' | 'entity' = 'intent';
  filter: any = {
    error: true,
    warning: true,
    info: true
  };
  idResearchTab: 'agent' | 'entity' | 'intent' = 'intent';
  oldTabValue: 'agent' | 'entity' | 'intent';

  results: any[] = [];
  resultResolved = true;
  resultsPagination: Pagination = new Pagination();
  subscriptions = {}
  dialogflowUrl = 'https://dialogflow.cloud.google.com/#/agent'

  constructor(private analyticsService: AnalyticsService, public authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.searchItems(1);
  }

  switchIdResearchTab(newVal) {
    switch(this.idResearchTab) {
      case 'agent':
        this.filterFor = 'issue';
        this.resetResult();
        break;
      case 'entity':
        if (this.filterFor === 'intent') this.filterFor = 'entity';
        if (this.oldTabValue === 'agent') this.resetResult();
        break;
      case 'intent':
        if (this.filterFor === 'entity') this.filterFor = 'intent';
        if (this.oldTabValue === 'agent') this.resetResult();
        break;
      default:
        break;
    }
    this.oldTabValue = newVal;
    this.searchItems(1);
  }

  searchItems(pageSelected: number) {

    this.resultsPagination.onSelectPage(pageSelected);

    if (this.subscriptions['searchItems']) this.subscriptions['searchItems'].unsubscribe();
    this.resultResolved = false;

    if (this.idResearchTab === 'intent') this.getHealthCheckIntent();
    if (this.idResearchTab === 'entity') this.getHealthCheckEntity();
    if (this.idResearchTab === 'agent') this.getHealthCheckAgent();
  }

  getHealthCheckIntent() {
    const params = {
      severity: this.getSeverity(),
      sortBy: this.filter.sortBy,
      intentName: this.filterFor === 'intent' ? this.filter.intent : undefined,
      errorMessage: this.filterFor === 'issue' ? this.filter.issue : undefined,
    }

    this.subscriptions['searchItems'] = this.analyticsService.getHealthCheckIntent(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((data: any) => {
          this.graphicAdjusment(data.content, 'editIntent')
          this.results = data.content;
          this.resultsPagination.updateTotals(data.totalElements);
          this.resultResolved = true;
        },
        () => {this.resultResolved = true;})
  }

  getHealthCheckEntity() {
    const params = {
      severity: this.getSeverity(),
      sortBy: this.filter.sortBy,
      intentName: this.filterFor === 'entity' ? this.filter.entity : undefined,
      errorMessage: this.filterFor === 'issue' ? this.filter.issue : undefined,
    }
    this.subscriptions['searchItems'] = this.analyticsService.getHealthCheckEntity(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
      .subscribe((data: any) => {
          this.graphicAdjusment(data.content, 'editEntity')
          this.results = data.content;
          this.resultsPagination.updateTotals(data.totalElements);
          this.resultResolved = true;
        },
        () => {this.resultResolved = true;})
  }

  getHealthCheckAgent() {
    const params = {
      severity: this.getSeverity(),
      errorMessage: this.filter.issue
    }
    this.subscriptions['searchItems'] = this.analyticsService.getHealthCheckAgent(params, this.resultsPagination.getPageIndex(), this.resultsPagination.pageSize)
    .subscribe((data: any) => {
        this.results = data.content && data.content.length > 0 ? data.content[0].errorMessages : [];
        this.resultsPagination.updateTotals(data.totalElements);
        this.resultResolved = true;
        },
        () => {this.resultResolved = true;})
  }

  onSort({column, direction}: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = (direction) ? `${column}:${direction}` : null;
    this.searchItems(this.resultsPagination.currentPage);
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  private graphicAdjusment(data, basePath) {
    data.forEach(
      el => {
        this.createItemLink(el, basePath);
        el.errorsCount = 0;
        el.warningsCount = 0;
        el.infoCount = 0;
        el.errorMessages.forEach(erroMessage => {
          switch(erroMessage.severity) {
            case 'ERROR':
              el.errorsCount++;
              break;
            case 'WARNING':
              el.warningsCount++;
              break;
            case 'INFO':
              el.infoCount++;
              break; 
            default:
              break;
          }
        })
        el.errorMessages.sort((message_A, message_B) => {
          if (message_A.severity === message_B.severity) {
            return 0;
          } else {
            switch(message_A.severity) {
              case 'ERROR':
                return -1;
              case 'INFO':
                return 1;
              case 'WARNING':
                return message_B.severity === 'ERROR' ? 1 : -1;
              default:
                return 1;
            }
          }
        })
      }
    );
  }

  private createItemLink(item: any, basePath: string) {
    item.link = `${this.dialogflowUrl}/${this.authenticationService.projectId}/${basePath}/${item.intentId.slice(item.intentId.lastIndexOf('/') + 1, item.intentId.length)}${basePath === 'editIntent' ? '/': ''}`
  }

  private getSeverity() {
    let severity = [];
    if (this.filter.error) severity.push('ERROR')
    if (this.filter.warning) severity.push('WARNING')
    if (this.filter.info) severity.push('INFO')
    return severity.length > 0 ? severity : null;
  }

  private resetResult() {
    this.results = [];
    this.resultsPagination.updateTotals(0);
  }

}
