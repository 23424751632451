import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { PlaceholderTypes } from 'src/app/enums/placeholder-types.enum';
import { PlaceholderService } from 'src/app/services/placeholder.service';

@Component({
  selector: 'payload-form',
  templateUrl: './payload-form.component.html',
  styleUrls: ['./payload-form.component.scss']
})
export class PayloadFormComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;

  @Input() payloadTypes = [];

  @Input() placeholderName;
  
  carouselOptions: OwlOptions = {
    dots: false,
    loop: false,
    autoWidth: true
  };

  idCardCarousel;

  payloadTypeEnum = PlaceholderTypes;

  cardGraphicTypes = ['generic-card', 'ce-card'];
  chipGraphicTypes = ['document-filter-suggestions', 'amount-charge-suggestions', 'generic-suggestions'];
  linkGraphicTypes = ['text-link'];
  buttonGraphicTypes = ['button'];

  
  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private placeholderService: PlaceholderService) { }

  ngOnInit() {
    this.enableDisable(this.form.value.type);
    let sub = this.form.get('type').valueChanges.pipe(distinctUntilChanged()).subscribe(
      val => {
        this.enableDisable(val);
      }
    )
    this.subscriptions.push(sub);
  }

  get carouselCard(): FormGroup {
    return <FormGroup>this.form.get('carouselCard');
  }

  get basicCard(): FormGroup {
    return <FormGroup>this.form.get('basicCard');
  }

  get simpleChip(): FormGroup {
    return <FormGroup>this.form.get('simpleChip');
  }

  get link(): FormGroup {
    return <FormGroup>this.form.get('link');
  }

  get button(): FormGroup {
    return <FormGroup>this.form.get('button');
  }
  
  get chipCarousel(): FormGroup {
    return <FormGroup>this.form.get('chipCarousel');
  }

  get items(): FormArray {
    let retVal: FormArray;
    switch(this.form.get('type').value) {
      case PlaceholderTypes.BASIC_CARD:
        retVal = <FormArray>this.basicCard.get('graphics').get('items');
        break;
      case PlaceholderTypes.CHIPS_CAROUSEL:
        retVal = <FormArray>this.chipCarousel.get('graphics').get('items');
        break;
      case PlaceholderTypes.SIMPLE_CHIP:
        retVal = <FormArray>this.simpleChip.get('graphics').get('items');
        break;
      case PlaceholderTypes.LINK:
        retVal = <FormArray>this.link.get('graphics').get('items');
        break;
      case PlaceholderTypes.BUTTON:
        retVal = <FormArray>this.button.get('graphics').get('items');
        break;
      case PlaceholderTypes.CARD_CAROUSEL:
        retVal = <FormArray>this.carouselCard.get('graphics').get('items');
        break;
      default:
        break;
    }
    return retVal;
  }

  addItemToCarousel() {
    switch(this.form.get('type').value) {
      case PlaceholderTypes.CARD_CAROUSEL:
        this.items.push(this.placeholderService.getBasicCardGroup());
        break;
      case PlaceholderTypes.CHIPS_CAROUSEL:
        this.items.push(this.placeholderService.getsimpleChipGroup());
        break;
      default:
        break;
    }
    this.carouselOptions.startPosition = this.idCardCarousel;
  }

  registerNewIdCarousel(e) {
    this.idCardCarousel = e.startPosition;
  }

  deleteSingleItem() {
    this.items.get('0').reset();
  }

  deleteFromCarousel(index: number) {
    this.items.controls.splice(index, 1);
    if (this.items.length === 0) {
      switch(this.form.get('type').value) {
        case PlaceholderTypes.CARD_CAROUSEL:
          this.items.push(this.placeholderService.getBasicCardGroup());
          break;
        case PlaceholderTypes.CHIPS_CAROUSEL:
          this.items.push(this.placeholderService.getsimpleChipGroup());
          break;
        default:
          break;
      }
    }
    this.carouselOptions.startPosition = this.idCardCarousel > 0 ? this.idCardCarousel - 1 : this.idCardCarousel;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private enableDisable(val) {
    this.placeholderService.disableGroupElementBut(this.form, 'type');
    switch (val) {
      case this.payloadTypeEnum.BASIC_CARD:
        this.basicCard.enable();
        break;
      case this.payloadTypeEnum.SIMPLE_CHIP:
        this.simpleChip.enable();
        break;
      case this.payloadTypeEnum.LINK:
        this.link.enable();
        break;
      case this.payloadTypeEnum.BUTTON:
        this.button.enable();
        break;
      case this.payloadTypeEnum.CHIPS_CAROUSEL:
        this.idCardCarousel = 0;
        this.carouselOptions.startPosition = 0;
        this.chipCarousel.enable();            
        break;
      case this.payloadTypeEnum.CARD_CAROUSEL:
        this.idCardCarousel = 0;
        this.carouselOptions.startPosition = 0;
        this.carouselCard.enable();            
        break;
      default:
        break;
    }
  }
}
