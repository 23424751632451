import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { PlaceholderService } from 'src/app/services/placeholder.service';
import { PlaceholderTypes } from '../../../enums/placeholder-types.enum'

@Component({
  selector: 'app-graphics-payload-modal',
  templateUrl: './graphics-payload-modal.component.html',
  styleUrls: ['./graphics-payload-modal.component.scss']
})
export class GraphicsPayloadModalComponent implements OnInit, OnDestroy {

  subscriptions: Array<Subscription> = [];

  placeholderGroup: FormGroup;

  @Input() placeholderObject;

  @Input() placeholderTypes = Object.values(PlaceholderTypes);

  @Input() action: 'CREATE' | 'EDIT' = 'CREATE';

  @Output() onSaveResponse = new EventEmitter<any>();
  
  payloadTypes = [];

  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private placeholderService: PlaceholderService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.initFormGroup();
    this.placeholderTypes.forEach(element => {
      if(element !== PlaceholderTypes.PLAIN_TEXT) {
        this.payloadTypes.push(element);
      }    
    })
  }

  get payloadGroup(): FormGroup {
    return <FormGroup>this.placeholderGroup.get('payloadGroup');
  }

  get textGroup(): FormGroup {
    return <FormGroup>this.placeholderGroup.get('textGroup');
  }

  keyupPlaceholder(e) {
    let pos = e.target.selectionEnd;
    let oldVal = e.target.value.replace(/\[/g,'');
    oldVal = oldVal.replace(/\]/g,'');
    let val = '[[' + oldVal + ']]';
    this.placeholderGroup.get('placeholder').patchValue(val.replace(/\s/g, '_'));
    e.target.selectionEnd = pos;
  }

  cursorInTheMiddle(e){
    if(e.target.value === '[[]]') {
      e.target.selectionEnd = 2
    }
  }

  save() {
    if(this.action === 'CREATE') {
      this.create();
    } else if (this.action === 'EDIT') {
      this.update();
    }
  }

  private initFormGroup() {
    
    this.placeholderGroup = this.placeholderService.createPlaceholderForm();

    if(this.action === 'CREATE') {
      this.initCreate();
    } else if (this.action === 'EDIT') {
      this.initEdit();
    }
    this.setVariableChange();
  }

  private initCreate() {
    this.placeholderGroup.get('placeholder').patchValue("[[]]")
    this.payloadGroup.disable();
  }

  private initEdit() {
    if (!!this.placeholderObject) {
      this.placeholderGroup.get('placeholder').patchValue(this.placeholderObject.key);
      if (this.placeholderObject.type === PlaceholderTypes.PLAIN_TEXT) {
        this.payloadGroup.disable();
        this.textGroup.get('value').patchValue(this.placeholderObject.value);
      } else {
        setTimeout(() => this.initPayload());
      }
    } else {
      this.action = 'CREATE'
      this.initCreate();
    }
  }

  private initPayload() {
    let valueJson = JSON.parse(this.placeholderObject.value.replace(/\n/g, '\\n'));
    this.placeholderGroup.get('type').patchValue('payload');
    this.textGroup.disable();
    this.payloadGroup.get('type').patchValue(this.placeholderObject.type);
    switch (this.placeholderObject.type) {
      case PlaceholderTypes.BASIC_CARD:
        this.payloadGroup.get('basicCard').patchValue(valueJson);
        break;
      case PlaceholderTypes.SIMPLE_CHIP:
        this.payloadGroup.get('simpleChip').patchValue(valueJson);
        break;
      case PlaceholderTypes.LINK:
        this.payloadGroup.get('link').patchValue(valueJson);
        break;
      case PlaceholderTypes.BUTTON:
        this.payloadGroup.get('button').patchValue(valueJson);
        break;
      case PlaceholderTypes.CHIPS_CAROUSEL:
        for (let i = 0; i <= valueJson.graphics.items.length - 2; i++) {
          (<FormArray>(this.payloadGroup.get('chipCarousel').get('graphics').get('items'))).push(this.placeholderService.getsimpleChipGroup());
        }
        this.payloadGroup.get('chipCarousel').patchValue(valueJson);
        break;
      case PlaceholderTypes.CARD_CAROUSEL:
        for (let i = 0; i <= valueJson.graphics.items.length - 2; i++) {
          (<FormArray>(this.payloadGroup.get('carouselCard').get('graphics').get('items'))).push(this.placeholderService.getBasicCardGroup());
        }
        this.payloadGroup.get('carouselCard').patchValue(valueJson);
        break;
      default:
        break;
    }
  }

  private setVariableChange() {
    this.placeholderGroup.get('type').valueChanges.pipe(distinctUntilChanged()).subscribe(
      val => {
        if (val === 'text') {
          this.textGroup.enable();
          this.payloadGroup.disable();
        } else {
          this.textGroup.disable();
          this.payloadGroup.enable();
        }
      }
    )
  }

  private create() {
    let placeholderNew = {
      key: this.placeholderGroup.get('placeholder').value,
      type: this.placeholderGroup.get('type').value === 'text' ? PlaceholderTypes.PLAIN_TEXT : this.payloadGroup.get('type').value,
      value: this.placeholderGroup.get('type').value === 'text' ? this.textGroup.value.value : this.getPayloadJson()
    }
    this.subscriptions['Create'] = this.placeholderService.createPlaceholder(placeholderNew).subscribe(() => this.responseSaved());
  }

  private update() {
    let placeholderNew = {
      id: this.placeholderObject.id,
      journeyId: this.placeholderObject.journeyId,
      key: this.placeholderGroup.get('placeholder').value,
      type: this.placeholderGroup.get('type').value === 'text' ? PlaceholderTypes.PLAIN_TEXT : this.payloadGroup.get('type').value,
      value: this.placeholderGroup.get('type').value === 'text' ? this.textGroup.value.value : this.getPayloadJson()
    }
    this.subscriptions['EDIT'] = this.placeholderService.updatePlaceholder(placeholderNew).subscribe(() => this.responseSaved());
  }

  private getPayloadJson() {
    let jsonValue = '';
    switch(this.payloadGroup.get('type').value) {
      case PlaceholderTypes.BASIC_CARD:
        jsonValue = JSON.stringify(this.payloadGroup.get('basicCard').value);
        break;
      case PlaceholderTypes.SIMPLE_CHIP:
        jsonValue = JSON.stringify(this.payloadGroup.get('simpleChip').value);
        break;
      case PlaceholderTypes.LINK:
        jsonValue = JSON.stringify(this.payloadGroup.get('link').value);
        break;
      case PlaceholderTypes.BUTTON:
          jsonValue = JSON.stringify(this.payloadGroup.get('button').value);
          break;
      case PlaceholderTypes.CHIPS_CAROUSEL:
        jsonValue = JSON.stringify(this.payloadGroup.get('chipCarousel').value);
        break;
      case PlaceholderTypes.CARD_CAROUSEL:
        jsonValue = JSON.stringify(this.payloadGroup.get('carouselCard').value);
        break;
      default:
        break;
    }
    return jsonValue;
  }

  private responseSaved() {
    this.onSaveResponse.emit();
    this.activeModal.close()
  }
}
