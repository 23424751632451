import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

//import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivateChild {

  constructor(private authenticationService: AuthenticationService, /*private cookieService: CookieService,*/ private router: Router, private notificationService: NotificationService) { }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.authenticationService.isLogged()) {
        /* if (this.cookieService.check('ocs_token') && this.cookieService.check('ocs_refresh')) {
          this.authenticationService.setToken({
            token: this.cookieService.get('ocs_token'),
            refreshToken: this.cookieService.get('ocs_refresh')
          });*/
          
          this.notificationService.showModalSpinner();
          this.authenticationService.getCurrentUser().subscribe((response: any) => {
            this.authenticationService.setUser(response);
            this.notificationService.hideModalSpinner();
            resolve(true);
          }, () => {
            this.authenticationService.eraseSession();
            this.notificationService.hideModalSpinner();
            this.router.navigate(['login']);
            reject(false);
          });
        /* } else {
          this.router.navigate(['login']);
          reject(false);
        }*/
      } else {
        resolve(true);
      }
    });
  }
  
}
