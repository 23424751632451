import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { asyncScheduler, Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class IntentService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  synchIntents(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/intents/synchronize`, data);
  }

  getTags(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams(),
    };
    if (params.isLogic) httpOptions.params = httpOptions.params.append('isLogic', params.isLogic);

    return this.http.get(`${environment.API.orchestrator_configurator}/intents/tags/getAll`, httpOptions);
  }

  getTagsSuggestions(tagName: string) {
    if (tagName === '') return of([]);

    const httpOptions = {
      params: new HttpParams().set('name', tagName).set('editable', 'true').set('isLogic', 'false'),
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/intents/tags/getAll`, httpOptions).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCategorySuggestions(categoryName: string) {
    if (categoryName === '') return of([]);

    const httpOptions = {
      params: new HttpParams().set('type', categoryName).set('editable', 'true'),
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/intents/tags/types`, httpOptions).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getIntentsSuggestions(intentName: string) {
    if (intentName === '') return of([]);

    const httpOptions = {
      params: new HttpParams().set('pageNumber', '0').set('pageSize', '100').set('name', intentName),
    };
    return this.http.get(`${environment.API.orchestrator_configurator}/intents`, httpOptions).pipe(
      map((respone: any) => {
        return respone.content;
      })
    );
  }

  getIntentsTags(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageNumber', pageNumber.toString()).set('pageSize', pageSize.toString()),
    };
    if (params.name) httpOptions.params = httpOptions.params.append('name', params.name);
    if (params.tag) httpOptions.params = httpOptions.params.append('tag', params.tag);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_configurator}/intents`, httpOptions);
  }

  updateTagsIntent(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/intents/tags`, data);
  }

  getIntentsParameters(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageNumber', pageNumber.toString()).set('pageSize', pageSize.toString()),
    };

    if (params.intentName) httpOptions.params = httpOptions.params.append('intentName', params.intentName);
    if (params.paramName) httpOptions.params = httpOptions.params.append('paramName', params.paramName);
    if (params.paramValue) httpOptions.params = httpOptions.params.append('paramValue', params.paramValue);
    if (params.sortBy) httpOptions.params = httpOptions.params.append('sortBy', params.sortBy);

    return this.http.get(`${environment.API.orchestrator_configurator}/intents/parameters`, httpOptions);
  }

  createIntentParameters(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/intents/parameters`, data, { observe: 'response' }).pipe(
      map((resp) => {
        if (resp.status === 204) {
          throw new Error(resp.headers.get('etag'));
        } else {
          return resp.body;
        }
      })
    );
  }
  updateIntentParameters(data: any): Observable<any> {
    return this.http.put(`${environment.API.orchestrator_configurator}/intents/parameters`, data, { observe: 'response' }).pipe(
      map((resp) => {
        if (resp.status === 204) {
          throw new Error(resp.headers.get('etag'));
        } else {
          return resp.body;
        }
      })
    );
  }

  deleteIntentParameters(intentParamId: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('intentParamId', intentParamId.toString()),
    };

    return this.http.delete(`${environment.API.orchestrator_configurator}/intents/parameters`, httpOptions);
  }

  exportExcel() {
    const httpOptions = {
      responseType: 'blob' as 'blob',
    };

    return this.http.get(`${environment.API.orchestrator_configurator}/export/exportConfiguration`, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Export Intents Data.xlsx');
      })
    );
  }
}
