import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'console-menu',
  templateUrl: './console-menu.component.html',
  styleUrls: ['./console-menu.component.scss']
})
export class ConsoleMenuComponent implements OnInit {
  @Input() stretchMenu: boolean;

  subHealthCheck: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  isChildActive(element: any) {
    for (let index = 0; index < element.querySelectorAll('ul.sub_items li').length; index++) {
      if (element.querySelectorAll('ul.sub_items li')[index].classList.contains('active')) return true;
    }
  }

}
