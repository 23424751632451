import { Directive, Input, Output, EventEmitter } from '@angular/core';

const rotate: { [key: string]: string } = { 'asc': 'desc', 'desc': '', '': 'asc' };

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.column_sortable]': 'true',
    '[class.asc_sorted]': 'direction === "asc"',
    '[class.desc_sorted]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class TableSortDirective {
  @Input() sortable: string = '';
  @Input() direction: string = '';
  @Output() sort = new EventEmitter<any>();

  constructor() { }

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }

}
