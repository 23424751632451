import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {

  private expireDate;
  private timer;
  private onRefreshSession$ = new Subject<any>();


  constructor(private notificationService: NotificationService) { }

  setExpireDate(date: Date) {
    this.expireDate = date;
    let notificationDate = new Date(this.expireDate);
    notificationDate.setMinutes(notificationDate.getMinutes() - 5);
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.notificationService.openModal({
        title: 'Session is expiring',
        message: 'The session is going to expire in 5 minutes',
      }).subscribe(() => {
        this.onRefreshSession$.next();
      });
      clearInterval(this.timer);
    }, (notificationDate.getTime() - new Date().getTime()))
  }

  onRefreshSession() {
    return this.onRefreshSession$.asObservable();
  }

}
