import { EventEmitter } from '@angular/core';

import { Pagination } from './pagination';

export class PaginationSlide {
    onMovePage = new EventEmitter<{direction: 'prev' | 'next', targetPage: number}>();
    onMoveSlide = new EventEmitter<string | number>();
    
    items: Array<any>;
    itemsPagination: Pagination;
    currentItem: any;
    itemAttributes: Array<string | number>;
    groupAttribute: string | number;

    constructor(items: Array<any>, itemsPagination: Pagination, currentItem: any, groupAttribute: string) {
        this.items = items;
        this.itemsPagination = itemsPagination;
        this.currentItem = currentItem;
        this.groupAttribute = groupAttribute;

        this.groupItems(groupAttribute);
    }

    groupItems(attribute: string | number) {
        this.itemAttributes = this.items.reduce((group, item) => {
            group.push(item[attribute]);
            return group;
        }, []);
    }

    movePrev() {
        const targetIndex = this.itemAttributes.indexOf(this.currentItem[this.groupAttribute]) - 1;
        if (!this.items[targetIndex]) {
            this.onMovePage.emit({ direction: 'prev', targetPage: this.itemsPagination.currentPage - 1 });
        } else {
            this.currentItem = this.items[targetIndex];
            this.onMoveSlide.emit(this.itemAttributes[targetIndex]);
        }
    }

    moveNext() {
        const targetIndex = this.itemAttributes.indexOf(this.currentItem[this.groupAttribute]) + 1;
        if (!this.items[targetIndex]) {
            this.onMovePage.emit({ direction: 'next', targetPage: this.itemsPagination.currentPage + 1 });
        } else {
            this.currentItem = this.items[targetIndex];
            this.onMoveSlide.emit(this.itemAttributes[targetIndex]);
        }
    }

    changePage(direction: 'prev' | 'next', items: Array<any>) {
        this.items = items;
        this.groupItems(this.groupAttribute);

        if (direction == 'prev') this.currentItem = this.items[this.items.length - 1];
        if (direction == 'next') this.currentItem = this.items[0];

        this.onMoveSlide.emit(this.currentItem[this.groupAttribute]);
    }

    isFirstItem(): boolean {
        return (this.itemsPagination.currentPage === 1 && this.itemAttributes.indexOf(this.currentItem[this.groupAttribute]) === 0);
    }

    isLastItem(): boolean {
        return (this.itemsPagination.currentPage === this.itemsPagination.totalPages && this.itemAttributes.indexOf(this.currentItem[this.groupAttribute]) === this.items.length - 1);
    }
}
