
export const environment = {
    production: false,
    API: {
        orchestrator_analysis: '/cor-be-analysis/api/v1',
        orchestrator_configurator: '/cor-be-configurator/api/v2',
        orchestrator_chat: '/cor-be-chatengine/api/v2',
        journeyKey: 'ly1XiLweu6vC9hwiKQW6'
    },
};
