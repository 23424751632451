import { Subject } from 'rxjs';
export class Chatbot {
    sessionId: number;
    sessionKey: string;
    sessionLang: string;
    sessionUserData: any;
    sessionDebug: any = {};

    inputMessage: string = '';
    dialog: Array<any> = [];

    onRestoreSession: Subject<any> = new Subject<any>();

    constructor(chatbotId: number, chatbotKey: string, chatbotLang: string, chatbotUserData?: any) {
        this.sessionId = chatbotId;
        this.sessionKey = chatbotKey;
        this.sessionLang = chatbotLang;
        this.sessionUserData = chatbotUserData;
    }

    restoreChatbot() {
        this.onRestoreSession.next();
    }

    processOrchestrateResponse(orchestrateResponse: any) {
        this.inputMessage = '';
        this.updateDialog('bot', orchestrateResponse.results.aiResponse.result.fulfillment.messages);
        this.updateDebugInfo(orchestrateResponse);

        this.catchAction(orchestrateResponse);
    }

    updateDialog(source: 'bot' | 'user', messages: Array<any> | string) {
        if (source === 'user') messages = [{text: messages}];
        this.dialog.push({ type: source, messages });
    }

    updateDebugInfo(orchestrateResponse: any) {
        this.sessionDebug = {
            apiResponse: orchestrateResponse,
            interaction: {
                userQuery: orchestrateResponse.results.aiResponse.result.resolvedQuery,
                parameters: orchestrateResponse.results.aiResponse.result.parameters,
                intent: orchestrateResponse.results.aiResponse.result.metadata.intentName,
                action: orchestrateResponse.results.aiResponse.result.action,
                contexts: this.processDebugContexts(orchestrateResponse.results.aiResponse.result.contexts || []),
                botResponse: this.processDebugMessages(orchestrateResponse.results.aiResponse.result.fulfillment.messages),
                confidence: orchestrateResponse.results.aiResponse.result.score,
                sentiment: (orchestrateResponse.results.naturalLanguageResponse || {}).sentiment
            }
        };
    }

    processDebugContexts(contexts: Array<any>): Array<string> {
        let contextsName: Array<string> = [];
        contexts.forEach((context: any) => {
            const splittedContext = context.name.split('/contexts/');
            contextsName.push(splittedContext[splittedContext.length - 1]);
        });
        return contextsName;
    }

    processDebugMessages(messages: Array<any>): Array<string> {
        let speechesMessage: Array<string> = [];
        messages.forEach((message: any) => {
            speechesMessage.push(message.text);
        });
        return speechesMessage;
    }

    catchAction(orchestrateResponse: any) {
        /* switch (orchestrateResponse.results.aiResponse.result.action) {
            case 'set-reb':
                this.sessionId = new Date().getTime();
                this.sessionUserData.reb = orchestrateResponse.results.aiResponse.result.parameters.REB;
                break;
            default:
                break;
        } */
    }

}
