import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  @Input() options: {
    title?: string;
    message: string;
    size?: string;
    choice?: 'single' | 'multi';
    type?: 'warning' | 'danger' | 'spinner';
  }

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  abort(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  }

}
