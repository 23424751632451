import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'simple-link',
  templateUrl: './simple-link.component.html',
  styleUrls: ['./simple-link.component.scss']
})
export class SimpleLinkComponent implements OnInit {

  @Input() form: FormGroup;

  @Output() delete = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  remove() {
    this.delete.emit();
  }

}
