
import { Injectable } from '@angular/core';

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct {
        let result: NgbDateStruct = null;
        if (value) {
            let date = value.split('/');
            result = {
                day: parseInt(date[0]),
                month: parseInt(date[1]),
                year: parseInt(date[2])
            };
        }
        return result;
    }

    format(date: NgbDateStruct): string {
        let result: string = null;
        if (date) {
            result = `${date.day}/${date.month}/${date.year}`;
        }
        return result;
    }

}
