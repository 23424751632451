import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input() rateScore: number;

  rateStar: number;

  constructor() { }

  ngOnInit() {
    this.rateStar = this.rateScore / 100;
  }

}
