import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Pagination } from '../../../classes/pagination';
import { NotificationService } from '../../../services/notification.service';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss']
})
export class AccessManagementComponent implements OnInit, OnDestroy {
  @ViewChild('userForm', { static: true }) private userForm: NgForm;

  subscriptions: Array<Subscription> = [];
  usersPagination: Pagination = new Pagination();

  users: Array<any> = [];
  newUser: any = {
    enabled: true
  };
  userRoles: Array<string> = [];

  constructor(private notificationService: NotificationService, private modalService: NgbModal, private adminService: AdminService) { }
  
  ngOnInit() {
    //this.getUserRoles();
    this.getUsers();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  /* getUserRoles() {
    this.subscriptions['UserRoles'] = this.adminService.getUserRoles().subscribe((response: Array<string>) => {
      this.userRoles = response;
    });
  } */

  getUsers() {
    this.subscriptions['Users'] = this.adminService.getUsers().subscribe((response: any) => {
      //console.log('[USERS]', response);
      this.users = response.content;
    });
  }

  createUser() {
    if (this.userForm.invalid) return;

    this.newUser.authorities = [this.newUser.authorities];

    this.subscriptions['CreateUser'] = this.adminService.createUser(this.newUser).subscribe((response: any) => {
      this.notificationService.showToast('User correctly created', { type: 'success' });
      this.userForm.resetForm();
      this.getUsers();
    });
  }

  /* editUser(userId: number) {
    const modalRef = this.modalService.open(UserModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.userId = userId;
    modalRef.componentInstance.userRoles = this.userRoles;
    modalRef.componentInstance.onSaveUser.subscribe($event => this.getUsers(this.usersPagination.currentPage));
  }

  removeUser(user: any) {
    this.subscriptions['ConfirmModalRemove'] = this.notificationService.openModal({
      title: 'Confirm remove User',
      message: `Are you sure to delete the user with E-Mail <strong>${user.email}</strong>?`,
      choice: 'multi'
    }).subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.subscriptions['DeleteUser'] = this.adminService.deleteUser(user.id).subscribe((response: any) => {
        this.notificationService.showToast(`User with E-Mail <strong>${user.email}</strong> has been correctly removed`, { type: 'success' });
        this.getUsers(1);
      });
    });
  } */

}
