import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';
import { SessionHandlerService } from './session-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public user: any;
  public projectId: string;
  public sessionToken: string;
  public sessionRefreshToken: string;

  private loggedIn: boolean = false;

  constructor(private http: HttpClient, private router: Router, /* private cookieService: CookieService,*/ private modalService: NgbModal, private sessionHandlerService: SessionHandlerService) { }

  login(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/login`, data);/* .pipe(
      tap((response: any) => {
        this.setToken(response);
      })
    );*/
  }

  logout(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/logout`, data).pipe(
      tap(() => {
        this.eraseSession();
      })
    );
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${environment.API.orchestrator_configurator}/auth`).pipe(tap(data => {
      this.projectId = data.projectId;
      this.sessionHandlerService.setExpireDate(new Date(data.expireDate));
    }));
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/reset-password/start`, data);
  }

  newPassword(data: any): Observable<any> {
    return this.http.post(`${environment.API.orchestrator_configurator}/auth/reset-password/complete`, data);
  }

  refreshToken(): Observable<any> {
    /* const httpOptions = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.sessionRefreshToken}`)
    }; */
    return this.http.get(`${environment.API.orchestrator_configurator}/auth/token`).pipe(
      tap((response: any) => {
        this.sessionHandlerService.setExpireDate(new Date(response));
      })
    );
  }

  /* setToken(tokens: { token: string, refreshToken: string }) {
    this.sessionToken = tokens.token;
    this.sessionRefreshToken = tokens.refreshToken;

    this.cookieService.set('ocs_token', tokens.token);
    this.cookieService.set('ocs_refresh', tokens.refreshToken);
  } */

  setUser(user: any) {
    this.user = user;
    this.loggedIn = (typeof user !== 'undefined' && user != null);
  }

  isAuthorized(restrictedRole: Array<string> | string): boolean {
    if (!Array.isArray(restrictedRole)) restrictedRole = [restrictedRole];

    return restrictedRole.some(restriction => this.user.authorities.includes(restriction));
  }

  isLogged(): boolean {
    return this.loggedIn;
  }

  eraseSession() {
    //this.cookieService.delete('ocs_token');
    //this.cookieService.delete('ocs_refresh');
    delete this.user;
    this.loggedIn = false;
    this.modalService.dismissAll();
    this.router.navigate(['/login']);
  }

}
