import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Chatbot } from '../../../classes/chatbot';

@Component({
  selector: 'app-chat-settings-modal',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss']
})
export class ChatSettingsModalComponent implements OnInit {
  @Input() botSession: Chatbot;

  settingsForm = this.fb.group({
    customData: this.fb.array([])
  });

  get sessionData() { return this.settingsForm.get('customData') as FormArray }

  settingsSubmitted: boolean = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit() {
    if (Object.keys(this.botSession.sessionUserData).length) {
      this.processFormArray(this.botSession.sessionUserData);
    } else {
      this.addCustomData();
    }
  }

  processFormArray(formData: any) {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        this.sessionData.push(this.fb.group(
          { key: key, value: formData[key] }
        ));
      }
    }
  }

  addCustomData() {
    this.sessionData.push(
      this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required]
        //description: ['']
      })
    );
  }

  saveSettings() {
    //console.log('[SUBMIT]', this.settingsForm);
    this.settingsSubmitted = true;
    if (this.settingsForm.invalid) return;

    this.botSession.sessionUserData = this.sessionData.value.reduce((userData, customSetting) => {
      userData[customSetting.key] = customSetting.value;
      return userData;
    }, {});

    this.activeModal.close();
    this.botSession.restoreChatbot();
  }

}
